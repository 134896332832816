import { useEffect, useState } from "react";
import { API_TOTALREWARD_SERVICE, restApiRequest } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

export default function (companyId) {
  const [imports, setImports] = useState([]);

  useEffect(() => {
    const getImports = async (companyId) => {
      try {
        const result = await restApiRequest(
          API_TOTALREWARD_SERVICE,
          "/omb/imports",
          "GET",
          {
            params: {
              tenant: companyId,
              "order[created.at]": "desc",
            },
          }
        );
        setImports(
          result.map((c) => ({
            value: c.id,
            label: `${c.originalFileName} (${
              c.created.at ? new Date(c.created.at).toLocaleString("pl-PL") : ""
            })`,
          }))
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "/src/Pages/Company/TotalReward/PerkUsers/useTotalRewardPeriods.js - 13"
        );
        dynamicNotification(
          error.message || __("Nie udało się pobrać słownika okresów"),
          "error"
        );
        setImports([]);
      }
    };
    getImports(companyId);
  }, [companyId]);

  return { imports };
}

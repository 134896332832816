import React, { useState } from "react";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import { totalRewardSettings } from "src/utils/RoleBasedSecurity/permissions";
import Form from "src/Components/Form/index";
import useMutateTotalRewardConfig from "src/Pages/Company/TotalReward/hooks/useMutateTotalRewardConfig";
import useQueryTotalRewardConfig from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardConfig";
import useQueryTotalRewardDictionary from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardDictionary";
import ContentLoading from "src/Components/Loading/contentLoading";
import { update } from "lodash";
import moment from "moment";

export default function TotalRewardSettingsForm({ companyId }) {
  const {
    data: config,
    isLoading: loadingConfig,
    isSuccess: successConfig,
    refetch: refreshConfig,
  } = useQueryTotalRewardConfig(companyId);
  const {
    data: functionalities,
    isLoading: loadingFunctionalities,
    isSuccess: successFunctionalities,
  } = useQueryTotalRewardDictionary(companyId);

  const mutation = useMutateTotalRewardConfig(companyId);

  const parseValue = (value, type) => {
    if (type === "date") {
      return moment(value).format("YYYY-MM-DD");
    }
    if (type === "boolean") {
      return value ? "1" : "0";
    }
    return String(value);
  };

  const saveConfig = async (data) => {
    await Promise.all(
      functionalities.map((f) => {
        const payload = {
          id: config.find((c) => c.configKey === f.key)?.id,
          tenant: companyId,
          configKey: f.key,
          configValue: parseValue(data[f.key], f.type),
          valueType: f.type,
        };
        return mutation.mutate(payload);
      })
    );
    await refreshConfig();
  };

  return (
    <ContentLoading show={loadingConfig || loadingFunctionalities}>
      {successConfig && successFunctionalities && (
        <SettignsForm
          saveConfig={saveConfig}
          config={config}
          functionalities={functionalities}
        />
      )}
    </ContentLoading>
  );
}

TotalRewardSettingsForm.propTypes = {
  companyId: PropTypes.string.isRequired,
};

const SettignsForm = ({ saveConfig, config, functionalities }) => {
  const parseValue = (value, type) => {
    if (type === "boolean") {
      return value === "1";
    }
    return value;
  };

  const defaultValues = functionalities.reduce((accu, item) => {
    // eslint-disable-next-line no-param-reassign
    accu[item.key] = parseValue(item.defaultValue, item.type);
    return accu;
  }, {});

  const formValues = config.reduce((accu, item) => {
    // eslint-disable-next-line no-param-reassign
    accu[item.configKey] = parseValue(item.configValue, item.valueType);
    return accu;
  }, {});

  const [data, setData] = useState({ ...defaultValues, ...formValues });

  const onChange = (key, value) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      update(updatedData, key, (v) => value);
      return updatedData;
    });
  };

  return (
    <Form
      id="totalRewardPerkForm"
      data={data}
      config={{
        title: `${__("Konfigurowanie modułu")}`,
        stickyTitle: true,
        onSubmit: () => saveConfig(data),
        defaultOnChange: onChange,
        buttons: [
          {
            id: "settingsSubmit",
            size: "lg",
            color: "primary",
            className: "mr-2",
            text: `${__("Zapisz")}`,
            type: "submit",
            permission: totalRewardSettings,
          },
        ],
        formGroups: [
          {
            formElements: functionalities.map((f) => ({
              id: f.key,
              label: f.title,
              type: f.type,
            })),
          },
        ],
      }}
    />
  );
};

SettignsForm.propTypes = {
  saveConfig: PropTypes.func.isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      configKey: PropTypes.string,
      configValue: PropTypes.string,
    })
  ).isRequired,
  functionalities: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      type: PropTypes.string,
      defaultValue: PropTypes.any,
    })
  ).isRequired,
};

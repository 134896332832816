import React from "react";
import __ from "src/utils/Translations";
import { match as matchPropTypes } from "react-router-prop-types";
import { useCompanyId } from "src/Pages/Company/CompanyContext";
import TotalRewardCategoriesForm from "src/Pages/Company/TotalReward/Categories/Edit/form";

export default function TotalRewardCategoryEdit({ match }) {
  const { categoryId } = match.params;
  const companyId = useCompanyId();
  const pathPrefix = `/ahr`;

  return (
    <TotalRewardCategoriesForm
      companyId={companyId}
      categoryId={categoryId}
      pathPrefix={pathPrefix}
    />
  );
}

TotalRewardCategoryEdit.propTypes = {
  match: matchPropTypes.isRequired,
};

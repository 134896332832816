import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import InspectorWrapper from "./devInspector/prod";
import * as serviceWorker from "./serviceWorker";

import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import getUserConfirmationPopup from "./utils/system/DOM/getUserConfirmationPopup";
import RbsAppWrapper from "./utils/RoleBasedSecurity/RbsAppWrapper";
import ScrollToTop from "./Layout/AppMain/ScrollToTop";
import { getVersion } from "./config/env";
import { FeatureFlagsProvider } from "./utils/FeatureFlags/FeatureFlagsContext";

const VERSION_KEY = "version";
const store = configureStore();
const rootElement = document.getElementById("root");

const featureFlags = {
  apiUrl: process.env.REACT_APP_FEATURE_FLAGS_URL || "",
};

/**
 * @todo It's a temporary solution; it should be removed after feature flags are fully implemented,
 * and OMB will be built into static JS instead of a working container.
 */
const featureFlagsDomainRegex =
  /https:\/\/(?<env>(dv1|qa1))(?:-(?<dv>[0-9]*).omb.mybenefit.g4n.eu|\.staging\.aws\.mybenefit\.pl|\.dev\.aws\.mybenefit\.pl)/g.exec(
    window.location.href
  );
/**
 * Check if any of:
 * https://dv1-<number>.omb.mybenefit.g4n.eu
 * https://qa1-<number>.omb.mybenefit.g4n.eu
 * https://qa1.staging.aws.mybenefit.pl
 * https://dv1.dev.aws.mybenefit.pl
 */
if (typeof featureFlagsDomainRegex?.groups?.dv !== "undefined") {
  if (featureFlagsDomainRegex?.groups?.env === "dv1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=dv1";
  } else if (featureFlagsDomainRegex?.groups?.env === "qa1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=qa1";
  }
} else if (typeof featureFlagsDomainRegex?.groups?.env !== "undefined") {
  if (featureFlagsDomainRegex?.groups?.env === "dv1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=dv1";
  } else if (featureFlagsDomainRegex?.groups?.env === "qa1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=qa1";
  }
}

const renderApp = (Component) => {
  const version = localStorage.getItem(VERSION_KEY);

  if (!version || version !== getVersion()) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(VERSION_KEY, getVersion());
  }

  ReactDOM.render(
    <InspectorWrapper
      keys={["control", "alt", "z"]}
      disableLaunchEditor={false}
      onHoverElement={(params) => {}}
      onClickElement={(params) => {}}
    >
      <Provider store={store}>
        <FeatureFlagsProvider options={featureFlags}>
          <RbsAppWrapper>
            <BrowserRouter getUserConfirmation={getUserConfirmationPopup}>
              <ScrollToTop />
              <Component />
            </BrowserRouter>
          </RbsAppWrapper>
        </FeatureFlagsProvider>
      </Provider>
    </InspectorWrapper>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    // eslint-disable-next-line global-require
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();

/* eslint-disable no-underscore-dangle, react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DataTableControlled from "src/Components/DataTableControlled";
import { getListingData } from "src/Components/DataTableControlled/utils";
import { booleanOptions } from "src/Components/DataTable/filters";
import { restApiRequest, REPORT_SERVICE } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import {
  SubscriptionListMock,
  formatDropdownMock,
  subscribeMethodMock,
} from "src/Pages/Report/Subscriptions/mockData";
import ToggleSwitch from "src/Components/FormElements/ToggleSwitch";
import UserConfirmationPopup from "src/Components/UserConfirmationPopup";
import { dynamicNotification } from "src/utils/Notifications";
import {
  SelectFilterWithTranslation,
  mapValueFromOptionsTranslation,
  translatedFrequencyText,
} from "src/Pages/Report/helpers/paramtersHelper";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";

const SubscriptionTable = ({ isAhr, editSubUrl, selectCompany }) => {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionListCount, setSubscriptionListCount] = useState(0);

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});

  const [subscribeMethodOptions, setSubscribeMethodOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);

  useEffect(() => {
    fetchSubscribeMethodDropdown();
    fetchSubscribeFormatDropdown();
  }, []);

  const columns = () => [
    {
      Header: "Nazwa raportu",
      accessor: "reportName",
      Cell: (rowData) => __(rowData.row.reportName),
    },
    {
      Header: "Utworzono",
      accessor: "createdStr",
    },
    {
      Header: "Sposób wysyłki",
      accessor: "subscriptionMethod",
      Filter: SelectFilterWithTranslation(subscribeMethodOptions),
      Cell: mapValueFromOptionsTranslation([], "subscriptionMethod"),
    },
    {
      Header: "Format i Kodowanie",
      accessor: "format",
      Filter: SelectFilterWithTranslation(formatOptions),
      Cell: mapValueFromOptionsTranslation([], "format"),
    },
    {
      Header: "Częstotliwość",
      accessor: "frequency",
      Cell: (rowData) => translatedFrequencyText(rowData.row.frequency),
      filterable: false,
    },
    {
      Header: "Adresaci",
      accessor: "recipients",
      filterable: false,
    },
    {
      Header: "Aktywna",
      accessor: "active",
      Filter: SelectFilterWithTranslation(booleanOptions),
      Cell: ({ row }) => (
        <div className="d-block w-100 text-center">
          <ToggleSwitch
            id={`switch${row._original.id}`}
            handleChange={(isOn) => {
              updateIsActive(row._original.id, isOn);
            }}
            checked={row._original.active}
          />
        </div>
      ),
    },
    {
      Header: "Akcja",
      maxWidth: 150,
      filterable: false,
      Cell: ({ row }) => (
        <div className="d-block w-100 text-center row">
          <Link to={editSubUrl(row._original.id)}>
            <Button role="button" color="link">
              {__("Edytuj")}
            </Button>
          </Link>
          {(!isAhr || (isAhr && row._original.isCreator)) && (
            <Button
              role="button"
              color="link"
              onClick={() => handleDeleteClick(row._original.id)}
            >
              {__("Usuń")}
            </Button>
          )}
        </div>
      ),
    },
  ];

  const updateIsActive = (id, isOn) => {
    const updatedData = [...subscriptionList];
    const item = updatedData.find((el) => el.id === id);
    item.active = isOn;

    fetchData(
      () => {
        setSubscriptionList(updatedData);
      },
      `/subscription/active/${id}&${isOn}`,
      REPORT_SERVICE,
      "PATCH",
      {},
      {},
      __("Błąd podczas zmiany aktywności wysyłki")
    );
  };

  const fetchSubscribeMethodDropdown = () => {
    fetchData(
      setSubscribeMethodOptions,
      "/subscription/methods",
      REPORT_SERVICE,
      "GET",
      {},
      subscribeMethodMock,
      __("Błąd podczas pobierania listy metod wysyłki")
    );
  };

  const fetchSubscribeFormatDropdown = () => {
    fetchData(
      setFormatOptions,
      "/reports/static/formats",
      REPORT_SERVICE,
      "GET",
      {},
      formatDropdownMock,
      __("Błąd podczas pobierania listy dostępnych formatów")
    );
  };

  const handleDeleteClick = (id) => {
    const subscription = subscriptionList.find((x) => x.id === id);
    setSelectedSubscription(subscription);

    setConfirmationPopup(true);
  };

  const deleteSubscriptionReport = () => {
    fetchData(
      () => {
        setSubscriptionList(
          subscriptionList.filter((x) => x.id !== selectedSubscription.id)
        );
        setSubscriptionListCount(subscriptionListCount - 1);
        setConfirmationPopup(false);
      },
      `/subscription/${selectedSubscription.id}`,
      REPORT_SERVICE,
      "DELETE",
      {},
      {},
      "Błąd podczas usuwania"
    );
  };

  const fetchSubscriptionList = useCallback(
    async (filters, page, pageSize, sort) => {
      const { data: newData, count: newCount } = await getListingData(
        REPORT_SERVICE,
        `/subscription/${selectCompany}`,
        filters,
        page,
        pageSize,
        sort,
        {},
        SubscriptionListMock
      );
      setSubscriptionList(newData);
      setSubscriptionListCount(newCount);
    },
    [selectCompany]
  );

  return (
    <>
      {selectCompany !== "" &&
        selectCompany !== "00000000-0000-0000-0000-000000000000" && (
          <DataTableControlled
            id="subsDTC"
            columns={columns()}
            data={subscriptionList}
            filterable
            fetchData={fetchSubscriptionList}
            count={subscriptionListCount}
          />
        )}
      <UserConfirmationPopup
        onCancel={() => setConfirmationPopup(false)}
        onConfirm={deleteSubscriptionReport}
        onClose={() => {}}
        isOpen={confirmationPopup}
        title={__("Usuwanie")}
        confirmLabel={__("Usuń")}
        cancelLabel={__("Anuluj")}
        message={`${
          __("Czy na pewno chcesz usunąć wysyłkę ") +
          __(selectedSubscription.reportName)
        }?`}
        confirmButtonColor="danger"
      />
    </>
  );
};

export default SubscriptionTable;
SubscriptionTable.propTypes = {
  isAhr: PropTypes.bool.isRequired,
  editSubUrl: PropTypes.func.isRequired,
  selectCompany: PropTypes.string.isRequired,
};

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params, body },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params, body },
        {}
      );

      updateData(result);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(e, "Pages/Report/Subscriptions/List/table.js - 262");
      dynamicNotification(error || e.message || "błąd", "error");
    }
  }
};

import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import __ from "src/utils/Translations";
import useIsAhr from "src/utils/hooks/useIsAhr";
import { subscriptionEmployeeSubscriptionItemAccept } from "src/utils/RoleBasedSecurity/permissions";
import { isArray } from "lodash";
import { getActiveId, getDefaultOpenedTab } from "./utils";
import SidebarMenuAnchor from "./SidebarMenuAnchor";

export default function SidebarMenu({ content, linkId, isSubMenu }) {
  const history = useHistory();
  const [activeId, setActiveId] = useState(
    linkId || getActiveId(content, history.location.pathname)
  );
  const [openedSubmenu, setOpenedSubMenu] = useState(() =>
    getDefaultOpenedTab(content, activeId)
  );
  const isAhr = useIsAhr();
  const toggleSubmenu = (submenuId) => {
    setOpenedSubMenu(submenuId === openedSubmenu ? null : submenuId);
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const newId = getActiveId(content, location.pathname);
      setActiveId(newId);
      if (newId) {
        const item = content.find((el) => el.id === newId);
        if (item && item.content && item.content.length) {
          toggleSubmenu(newId);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history, content, setActiveId, toggleSubmenu]);

  const isPendingBenefitsItem = useMemo(
    () => content.filter((ele) => ele.id === "pendingBenefits"),
    [content]
  );

  const contentUpdated = useMemo(() => {
    const contentCopy = isArray(content) ? [...content] : [];
    if (
      isAhr &&
      Boolean(!subscriptionEmployeeSubscriptionItemAccept) &&
      isPendingBenefitsItem
    ) {
      const indexOf = contentCopy.indexOf(isPendingBenefitsItem[0]);
      contentCopy.splice(indexOf, 1);
    }

    return contentCopy;
  }, [
    content,
    isAhr,
    subscriptionEmployeeSubscriptionItemAccept,
    isPendingBenefitsItem,
  ]);

  return (
    <div
      className={
        isSubMenu
          ? "sub-menu metismenu vertical-nav-menu"
          : "main-menu metismenu vertical-nav-menu"
      }
    >
      <ul className="metismenu-container" data-t1="sidebarMenu">
        {contentUpdated.map((item) => {
          const {
            id,
            to,
            content: itemContent,
            label,
            icon,
            href,
            before,
          } = item;
          const hasSubmenu = itemContent && itemContent.length;
          const hasActiveChild =
            hasSubmenu &&
            itemContent.find((subItem) => subItem.id === activeId);
          const isOpen = hasSubmenu && openedSubmenu === id;
          const isActive = activeId === id;

          return (
            <li className="metismenu-item" key={id}>
              <SidebarMenuAnchor
                id={id}
                href={href}
                before={before}
                isOpen={isOpen}
                isActive={isActive}
                hasActiveChild={hasActiveChild}
                hasSubmenu={hasSubmenu}
                to={to}
                icon={icon}
                label={label}
                toggleSubmenu={toggleSubmenu}
              />
              {hasSubmenu ? (
                <ul
                  className={`metismenu-container${isOpen ? " visible" : ""}`}
                >
                  {itemContent.map(
                    (subItem) =>
                      !subItem?.notDisplay && (
                        <li className="metismenu-item" key={subItem.id}>
                          <Link
                            data-t1={subItem.id}
                            className={`metismenu-link${
                              activeId === subItem.id ? " active" : ""
                            }`}
                            to={subItem.to}
                            href={subItem.href}
                          >
                            <i
                              className={`metismenu-icon${
                                subItem.icon ? ` ${subItem.icon}` : ""
                              }`}
                            />
                            {__(subItem.label)}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

SidebarMenu.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      strict: PropTypes.bool,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          to: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
          strict: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  linkId: PropTypes.string,
  isSubMenu: PropTypes.bool.isRequired,
};

SidebarMenu.defaultProps = {
  linkId: "",
};

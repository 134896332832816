import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Responsive, WidthProvider } from "react-grid-layout";
import { restApiRequest, REPORT_SERVICE, getServiceHost } from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import { getLanguage } from "src/utils/Languages/LanguageContext";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import __ from "src/utils/Translations";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Select from "react-select";
import MultiSelectParameter from "src/Pages/Report/NewReport/ReportsList/Report/multiSelectParameter";
import Spinner from "src/Components/Spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const ResponsiveGridLayout = WidthProvider(Responsive);

const VisualReport = ({ companyId }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [tmpMenuParameters, setTmpMenuParameters] = useState([]);

  const server = getServiceHost(REPORT_SERVICE);
  const converterUrl = `${server}/reports/graphs/pdf`;

  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  useEffect(() => {
    const fetchChartTypes = () => {
      fetchData(
        (result) => {
          setChartsDropdownDef(result);
        },
        `/reports/graphs/types`,
        REPORT_SERVICE,
        "GET",
        {
          headers: {
            "Accept-Language": getLanguage(),
          },
        },
        null,
        __("Błąd podczas pobierania listy wykresów")
      ).then((result) => {});
    };
    fetchChartTypes();

    const fetchChartConfiguration = () => {
      fetchData(
        (result) => {
          setChartsConfiguration(result);
        },
        `/reports/graphs/configuration`,
        REPORT_SERVICE,
        "GET",
        {
          headers: {
            "Accept-Language": getLanguage(),
          },
        },
        null,
        __("Błąd podczas pobierania konfiguracji")
      ).then((result) => {});
    };

    fetchChartConfiguration();
  }, []);

  const ShadowPlugin = {
    pluginId: 1,
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      ctx.shadowColor = "#fff";
      ctx.shadowBlur = 1;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    },
  };

  const DoughnutCenterTextPlugin = {
    pluginId: 2,
    beforeDraw: (chart, args, options) => {
      if (
        chart?.data == null ||
        chart?.data?.datasets == null ||
        chart?.data?.datasets?.length === 0
      ) {
        return;
      }

      const { width } = chart;
      const { height } = chart;
      const { ctx } = chart;

      ctx.restore();
      const fontSize = (Math.min(height, width) / 150).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = chart.data.datasets[0].data[0];
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 + 18;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const ChartPlugins = [ShadowPlugin, DoughnutCenterTextPlugin];

  const [chartsDropdownDef, setChartsDropdownDef] = useState([]);
  const [chartsConfiguration, setChartsConfiguration] = useState();
  const [selectedChartType, setSelectedChartType] = useState();
  const [layoutState, setLayoutState] = useState({ layout: [] });
  const [dashboardCharts, setDashboardCharts] = useState([]);
  const [coverVisible, setCoverVisible] = useState(false);

  const fetchData = async (
    updateData,
    endpoint,
    service,
    method,
    { headers, params, body },
    mockData,
    error
  ) => {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params, body },
        {}
      );
      updateData(result);
    } catch (e) {
      console.error(e);
      dynamicNotification(
        `${e.error}: ${e.message}` || `${error}` || "błąd",
        "error"
      );
    }
  };

  const fetchChart = (e) => {
    setIsButtonDisabled(true);
    fetchData(
      // eslint-disable-next-line no-param-reassign
      (result) => {
        // eslint-disable-next-line no-param-reassign
        result.plugins = result.plugins.map((x) =>
          // eslint-disable-next-line
          ChartPlugins.find((y) => y.pluginId == x)
        );
        // eslint-disable-next-line no-param-reassign
        result.isOpen = false;
        if (
          result &&
          result.options &&
          result.options.plugins &&
          result.options.plugins.datalabels &&
          result.options.plugins.datalabels.formatter
        ) {
          // eslint-disable-next-line
          result.options.plugins.datalabels.formatter = new Function(
            "value",
            "context",
            result.options.plugins.datalabels.formatter
          );
        }

        for (let i = 0; i < result.parameters.length; i += 1) {
          if (result.parameters[i].multiValue) {
            // eslint-disable-next-line
            result.parameters[i].values = [
              {
                label: result.parameters[i].selectOptions.find(
                  (o) => o.value === result.parameters[i].defaultValue[0]
                ).label,
                value: result.parameters[i].defaultValue[0],
              },
            ];
          } else {
            // eslint-disable-next-line
            result.parameters[i].values = {
              label: result.parameters[i].selectOptions.find(
                (o) => o.value === result.parameters[i].defaultValue[0]
              ).label,
              value: result.parameters[i].defaultValue[0],
            };
          }
        }

        addTile(result, selectedChartType);
      },
      `/reports/graphs/`,
      REPORT_SERVICE,
      "POST",
      {
        body: {
          report: selectedChartType,
          client: companyId,
          parameters: null,
        },
      },
      null,
      __("Błąd podczas pobierania wykresu")
    )
      .then((result) => {})
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const addTile = (chartData, selectedChartType) => {
    const newId =
      dashboardCharts.length > 0
        ? Math.max(...dashboardCharts.map((x) => x.id)) + 1
        : 0;
    const maxValueY =
      layoutState.layout?.length > 0
        ? Math.max(...layoutState.layout.map((chart) => chart.y))
        : 0;
    const lastChartFromLastRow = layoutState.layout
      .filter((x) => x.y === maxValueY)
      .sort((x, y) => x.x - y.x)
      .slice(-1);
    let lastXOccupiedCellFromLastRow = -1;

    if (chartData.emptyDataSpacing) {
      // eslint-disable-next-line
      chartData.data.datasets[0].data = [
        null,
        ...chartData.data.datasets[0].data,
        null,
      ];
      // eslint-disable-next-line
      chartData.data.labels = ["", ...chartData.data.labels, ""];
    }

    if (chartData.xAxisLabelMaxLineLength) {
      // eslint-disable-next-line
      chartData.data.labels = splitLabelToLines(
        chartData.data.labels,
        chartData.xAxisLabelMaxLineLength
      );
    }

    if (lastChartFromLastRow.length > 0) {
      lastXOccupiedCellFromLastRow =
        lastChartFromLastRow[0].x + lastChartFromLastRow[0].w - 1;
    }
    const newChart = {
      ...chartData,
      id: newId,
      reportUid: selectedChartType,
      gridCell: {
        ...chartData.gridCell,
        x:
          lastXOccupiedCellFromLastRow <= 3
            ? lastXOccupiedCellFromLastRow + 1
            : 0,
        y: lastXOccupiedCellFromLastRow <= 3 ? maxValueY : maxValueY + 1,
      },
    };
    if (!newChart.isMultipleColorData) {
      newChart.data.datasets = newChart.data.datasets.map((ds, dsIndex) => {
        // eslint-disable-next-line
        ds.backgroundColor = [
          chartsConfiguration.colorPalettes.find(
            // eslint-disable-next-line
            (x) => x.id == chartsConfiguration.selectedPaletteId
          ).palette[dsIndex],
        ];
        // eslint-disable-next-line
        ds.borderColor = ds.backgroundColor;
        return ds;
      });
    } else {
      newChart.data.datasets = newChart.data.datasets.map((ds, dsIndex) => {
        // eslint-disable-next-line
        ds.backgroundColor = chartsConfiguration.colorPalettes.find(
          // eslint-disable-next-line
          (x) => x.id == chartsConfiguration.selectedPaletteId
        ).palette;
        return ds;
      });
    }
    setDashboardCharts((state) => [...state, newChart]);
  };

  const generateChartRenderInfo = () => {
    const chartsData = layoutState.layout.map((layout) => ({
      X: layout.x,
      Y: layout.y,
      Width: layout.w,
      Height: layout.h,
      // eslint-disable-next-line
      ChartUniqueId: dashboardCharts.find((x) => x.id == layout.i).chartTypeId,
      ChartModel: {
        // eslint-disable-next-line
        report: dashboardCharts.find((x) => x.id == layout.i)?.reportUid,
        client: companyId,
        parameters: dashboardCharts
          // eslint-disable-next-line
          .find((x) => x.id == layout.i)
          ?.parameters.filter((x) => x.values != null)
          .map((p) => {
            if (!p.multiValue) {
              return {
                name: p.parameterName,
                value: p.values.value,
              };
            }
            return {
              name: p.parameterName,
              value: p.values.map((param) => param.value).join(","),
            };
          }),
      },
    }));
    return chartsData;
  };

  const onLayoutChange = (layout) => {
    setLayoutState({
      layout,
    });
  };

  const closeTooltip = (e) => {
    e.target.dispatchEvent(new MouseEvent("mouseout"));
  };

  const removeChart = (id) => {
    // eslint-disable-next-line
    setDashboardCharts((state) => state.filter((x) => x.id != id));
    setIsMenuOpened(false);
  };

  const chartUpdate = (x) => {
    setIsButtonDisabled(true);
    const { id } = x;
    // eslint-disable-next-line
    let updItemIdx = dashboardCharts.findIndex((x) => x.id == id);
    let updItem = dashboardCharts[updItemIdx];
    updItem.isLoading = true;
    setDashboardCharts((state) => [
      ...state.slice(0, updItemIdx),
      updItem,
      ...state.slice(updItemIdx + 1),
    ]);

    fetchData(
      (result) => {
        updItem.data.labels = result.data.labels;
        updItem.data.datasets = result.data.datasets;

        if (result.emptyDataSpacing) {
          updItem.data.datasets[0].data = [
            null,
            ...updItem.data.datasets[0].data,
            null,
          ];
          updItem.data.labels = ["", ...updItem.data.labels, ""];
        }

        if (updItem.xAxisLabelMaxLineLength) {
          updItem.data.labels = splitLabelToLines(
            updItem.data.labels,
            updItem.xAxisLabelMaxLineLength
          );
        }

        if (!updItem.isMultipleColorData) {
          updItem.data.datasets = updItem.data.datasets.map((ds, dsIndex) => {
            // eslint-disable-next-line
            ds.backgroundColor = [
              chartsConfiguration.colorPalettes.find(
                // eslint-disable-next-line
                (x) => x.id == chartsConfiguration.selectedPaletteId
              ).palette[dsIndex],
            ];
            // eslint-disable-next-line
            ds.borderColor = ds.backgroundColor;
            return ds;
          });
        } else {
          updItem.data.datasets = updItem.data.datasets.map((ds, dsIndex) => {
            // eslint-disable-next-line no-param-reassign
            ds.backgroundColor = chartsConfiguration.colorPalettes.find(
              // eslint-disable-next-line
              (x) => x.id == chartsConfiguration.selectedPaletteId
            ).palette;
            return ds;
          });
        }
        if (
          updItem &&
          updItem.options &&
          updItem.options.plugins &&
          updItem.options.plugins.datalabels &&
          updItem.options.plugins.datalabels.formatter
        ) {
          // eslint-disable-next-line
          updItem.options.plugins.datalabels.formatter = new Function(
            "value",
            "context",
            result.options.plugins.datalabels.formatter
          );
        }

        // eslint-disable-next-line
        updItemIdx = dashboardCharts.findIndex((x) => x.id == id);
        updItem = dashboardCharts[updItemIdx];
        setDashboardCharts((state) => [
          ...state.slice(0, updItemIdx),
          {
            ...updItem,
            id,
          },
          ...state.slice(updItemIdx + 1),
        ]);
      },
      `/reports/graphs/`,
      REPORT_SERVICE,
      "POST",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
        body: {
          report: x.reportUid,
          client: companyId,
          parameters: x.parameters
            .filter((x) => x.values != null)
            .map((p) => {
              if (!p.multiValue) {
                return {
                  name: p.parameterName,
                  value: p.values.value,
                };
              }
              return {
                name: p.parameterName,
                value: p.values.map((param) => param.value).join(","),
              };
            }),
        },
      },
      null,
      __("Błąd")
    )
      .then((result) => {
        updItem.isLoading = false;
        setDashboardCharts((state) => [
          ...state.slice(0, updItemIdx),
          updItem,
          ...state.slice(updItemIdx + 1),
        ]);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const splitLabelToLines = (labelsArr, maxLength) => {
    const regex = new RegExp(
      `\\s*(?:(\\S{${maxLength}})|([\\s\\S]{1,${maxLength}})(?!\\S))`,
      "g"
    );

    for (let i = 0; i < labelsArr.length; i += 1) {
      // eslint-disable-next-line
      labelsArr[i] = labelsArr[i]
        .replace(regex, ($0, $1, $2) => ($1 ? `${$1}-\n` : `${$2}\n`))
        .split("\n");
    }
    return labelsArr;
  };

  const renderChartTypes = () => {
    if (Array.isArray(chartsDropdownDef)) {
      return chartsDropdownDef.map((item) => (
        <option key={item.uid} value={item.uid}>
          {item.name}{" "}
        </option>
      ));
    }
    return null;
  };

  const selectedChartTypeChange = (e) => {
    const val = e.target.value;
    if (val !== 0 && val !== "0") {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setSelectedChartType(e.target.value);
  };

  const generatePdf = async (e) => {
    setIsButtonDisabled(true);
    const session = getSession();
    const token = await session.getValidAccessToken();
    let body = "";

    setCoverVisible(true);
    new Promise((res) => setTimeout(res, 2000)).finally(() => {
      const renderInfo = generateChartRenderInfo();
      const configuration = chartsConfiguration;
      body = {
        renderInfo,
        configuration,
      };

      // eslint-disable-next-line
      if (body.length == 0) {
        dynamicNotification(
          __(
            "Do wygenerowania raportu graficznego PDF wymagane jest wybranie co najmniej jednego wykresu."
          ),
          "info"
        );
      } else {
        fetch(converterUrl, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "content-language": getLanguage(),
            Authorization: `Bearer ${token}`,
            "Accept-Language": getLanguage(),
          },
          body: JSON.stringify(body),
        })
          .then(async (response) => {
            if (response.ok) {
              const filename = response.headers
                .get("Content-Disposition")
                .split('filename="')[1]
                .split('"')[0];
              const blob = await response.blob();

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            }
          })
          .finally(() => {
            setIsButtonDisabled(false);
            setCoverVisible(false);
          });
      }
    });
  };

  const getBackgroundImage = (bg) => {
    if (bg.startsWith("data:image")) return { backgroundImage: `url('${bg}')` };
    return { background: bg };
  };

  const selectColorPalette = (choosenPalette) => {
    setChartsConfiguration({
      ...chartsConfiguration,
      selectedPaletteId: choosenPalette.id,
    });
    const tempCharts = dashboardCharts.map((element, elementIndex) => {
      if (!element.isMultipleColorData) {
        return {
          ...element,
          data: {
            ...element.data,
            datasets: element.data.datasets.map((ds, dsIndex) => {
              // eslint-disable-next-line
              ds.backgroundColor = [choosenPalette.palette[dsIndex]];
              // eslint-disable-next-line
              ds.borderColor = ds.backgroundColor;
              return ds;
            }),
          },
        };
      }
      return {
        ...element,
        data: {
          ...element.data,
          datasets: element.data.datasets.map((ds, dsIndex) => {
            // eslint-disable-next-line
            ds.backgroundColor = choosenPalette.palette;
            return ds;
          }),
        },
      };
    });
    setDashboardCharts(tempCharts);
  };

  const selectBackground = (id) => {
    setChartsConfiguration({
      ...chartsConfiguration,
      selectedBackgroundImageId: id,
    });
  };

  const handleHtmlHeaderChange = (event) => {
    setChartsConfiguration({
      ...chartsConfiguration,
      htmlHeader: event.target.value,
    });
  };

  const handleHtmlFooterChange = (event) => {
    setChartsConfiguration({
      ...chartsConfiguration,
      htmlFooter: event.target.value,
    });
  };

  const toggleChartDropdownMenu = (x, isOpening = true) => {
    if (isOpening && isMenuOpened) {
      return false;
    }

    let isUpdateRequired = false;
    if (!x.isOpen) {
      setTmpMenuParameters(JSON.parse(JSON.stringify(x.parameters)));
    } else {
      for (let i = 0; i < x.parameters.length; i += 1) {
        if (
          JSON.stringify(x.parameters[i].values) !==
          JSON.stringify(tmpMenuParameters[i].values)
        ) {
          isUpdateRequired = true;
          i = x.parameters.length;
        }
      }
    }

    // eslint-disable-next-line
    x.isOpen ? (x.isOpen = false) : (x.isOpen = true);
    setIsMenuOpened(x.isOpen);
    setDashboardCharts((prev) => [...prev]);

    if (!x.isOpen && isUpdateRequired) {
      // eslint-disable-next-line
      x.parameters = tmpMenuParameters;
      chartUpdate(x);
    }

    return true;
  };

  const toggleSaveChartDropdownMenu = (x) => {
    toggleChartDropdownMenu(x, false);
  };

  const toggleCancelChartDropdownMenu = (x) => {
    setTmpMenuParameters([]);
    // eslint-disable-next-line
    x.isOpen = false;
    setIsMenuOpened(false);
  };

  const updateParameterStateSelect = (
    dashboardChartIndex,
    newValue,
    paramName
  ) => {
    // setDashboardCharts((prev) => {
    //   const tmp = [...prev];

    //   const paramIndex = dashboardCharts[
    //     dashboardChartIndex
    //   ].parameters.findIndex((x) => x.parameterName === paramName);
    //   tmp[dashboardChartIndex].parameters[paramIndex].values = newValue;
    //   return tmp;
    // });

    setTmpMenuParameters((prev) => {
      const tmp = [...prev];

      const paramIndex = tmp.findIndex((x) => x.parameterName === paramName);
      tmp[paramIndex].values = newValue;
      return tmp;
    });
  };

  const updateParameterStateMultiSelect = (
    dashboardChartIndex,
    newValue,
    paramName
  ) => {
    // setDashboardCharts((prev) => {
    //   const tmp = [...prev];

    //   const multiArr = [];

    //   newValue.map((item) =>
    //     multiArr.push({ label: item.label, value: item.value })
    //   );

    //   const paramIndex = dashboardCharts[
    //     dashboardChartIndex
    //   ].parameters.findIndex((x) => x.parameterName === paramName);
    //   tmp[dashboardChartIndex].parameters[paramIndex].values = multiArr;
    //   return tmp;
    // });

    setTmpMenuParameters((prev) => {
      const tmp = [...prev];

      const multiArr = [];

      newValue.map((item) =>
        multiArr.push({ label: item.label, value: item.value })
      );

      const paramIndex = tmp.findIndex((x) => x.parameterName === paramName);
      tmp[paramIndex].values = multiArr;
      return tmp;
    });
  };

  return (
    <>
      <div style={{ marginBottom: "200px", position: "relative" }}>
        <div className="input-group" style={{ display: "flex" }}>
          <select
            /* eslint-disable react/no-unknown-property */
            className="form-control"
            style={{
              display: "inline-block",
              flex: "none",
              minWidth: 200,
              width: "max-content",
            }}
            onChange={selectedChartTypeChange}
            onLoad={selectedChartTypeChange}
          >
            {isButtonDisabled && (
              <option value="0">{__("Wybierz raport")}</option>
            )}
            {renderChartTypes()}
          </select>
          <button
            type="button"
            className="form-control col-1"
            onClick={fetchChart}
            disabled={isButtonDisabled || isMenuOpened}
          >
            {__("Dodaj")}
          </button>
          <button
            type="button"
            className="form-control col-1"
            onClick={generatePdf}
            disabled={isButtonDisabled || isMenuOpened}
          >
            {__("PDF")}
          </button>
          <button
            type="button"
            className="form-control col-2"
            style={{ marginLeft: "auto" }}
            onClick={modalToggle}
          >
            {__("Ustawienia raportu")}
          </button>
        </div>
        <div>
          <ResponsiveGridLayout
            className="layout"
            cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
            rowHeight={400}
            compactType={null}
            resizeHandles={["se"]}
            layout={layoutState.layout}
            onLayoutChange={onLayoutChange}
            preventCollision
            isDraggable={!isMenuOpened}
          >
            {dashboardCharts.map((x, chartIdx) => (
              <div
                className="custom-material-tile"
                key={x.id}
                data-grid={x.gridCell}
                style={{
                  border:
                    // eslint-disable-next-line
                    layoutState.layout.find((obj) => obj.i == x.id)?.y %
                      // eslint-disable-next-line
                      2 ==
                      1 &&
                    // eslint-disable-next-line
                    layoutState.layout.find((obj) => obj.i == x.id)?.h > 1
                      ? "2px solid #f79292"
                      : "",
                  zIndex: x.isOpen ? 100 : 0,
                }}
              >
                {
                  // eslint-disable-next-line
                  layoutState.layout.find((obj) => obj.i == x.id)?.y % 2 == 1 &&
                    // eslint-disable-next-line
                    layoutState.layout.find((obj) => obj.i == x.id)?.h > 1 && (
                      <div
                        style={{
                          position: "absolute",
                          background: "#f32020",
                          color: "#fff",
                          padding: "2px 10px",
                          borderRadius: 30,
                          top: 5,
                          left: 5,
                          fontSize: "10px",
                        }}
                      >
                        {__("Blokuje PDF")}
                      </div>
                    )
                }
                {
                  // eslint-disable-next-line
                  x.data.datasets.length == 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <span style={{ lineHeight: "350px" }}>
                        {__("Brak danych dla wybranych parametrów")}
                      </span>
                    </div>
                  )
                }
                {isMenuOpened === true && (
                  <div
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "#9d9d9d8c",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      borderRadius: "6px",
                    }}
                  />
                )}
                {x.data.datasets.length > 0 && x.graphType === 2 ? (
                  <Bar
                    options={x.options}
                    data={x.data}
                    plugins={x.plugins}
                    id={`canva${x.id}`}
                    onMouseOut={(e) => closeTooltip(e)}
                  />
                ) : (
                  ""
                )}
                {x.data.datasets.length > 0 && x.graphType === 3 ? (
                  <Doughnut
                    options={x.options}
                    data={x.data}
                    plugins={x.plugins}
                    id={`canva${x.id}`}
                    onMouseOut={(e) => closeTooltip(e)}
                  />
                ) : (
                  ""
                )}
                {x.data.datasets.length > 0 && x.graphType === 1 ? (
                  <Line
                    options={x.options}
                    data={x.data}
                    plugins={x.plugins}
                    id={`canva${x.id}`}
                    onMouseOut={(e) => closeTooltip(e)}
                  />
                ) : (
                  ""
                )}
                {}
                <Dropdown
                  size="100px"
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    fontSize: "18px",
                  }}
                  // eslint-disable-next-line
                  isOpen={x.isOpen == true}
                  // eslint-disable-next-line
                  toggle={() => {
                    return false;
                  }}
                >
                  <DropdownToggle
                    caret={false}
                    style={{
                      border: "none",
                      background: "none",
                      backgroundColor: "usnet",
                      color: "#777",
                    }}
                    // eslint-disable-next-line
                    onClick={function toggle() {
                      toggleChartDropdownMenu(x);
                    }}
                  >
                    <i className="nav-link-icon pe-7s-settings"> </i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => removeChart(x.id)}
                      style={{ zIndex: "unset" }}
                    >
                      {__("Usuń")}
                    </DropdownItem>
                    <DropdownItem toggle={false} divider />
                    {isMenuOpened === true &&
                      tmpMenuParameters.map((p, i) => (
                        <DropdownItem
                          toggle={false}
                          active={false}
                          onClick={() => {}}
                          style={{ display: "block", zIndex: "unset" }}
                        >
                          <div> {p.label} </div>

                          {p.multiValue === true && (
                            <MultiSelectParameter
                              isMulti
                              disabledParam={false}
                              name="multiselect-params"
                              className={
                                p.isRequired &&
                                (p.values.length === 0 || p.values === null)
                                  ? "basic-multi-select non-valid-parameter"
                                  : "basic-multi-select"
                              }
                              classNamePrefix="select"
                              options={p.selectOptions}
                              allowSelectAll
                              hideSelectedOptions={false}
                              onChange={(idx) =>
                                updateParameterStateMultiSelect(
                                  chartIdx,
                                  idx,
                                  p.parameterName
                                )
                              }
                              required={p.isRequired}
                              value={
                                p.values != null
                                  ? p.values
                                  : {
                                      label: p.selectOptions.find(
                                        (o) => o.value === p.defaultValue[0]
                                      ).label,
                                      value: p.defaultValue[0],
                                    }
                              }
                              showAllSelected
                            />
                          )}
                          {p.multiValue === false && (
                            <Select
                              name="select-params"
                              className={
                                p.isRequired &&
                                (p.values.length === 0 || p.values === null)
                                  ? "basic-select non-valid-parameter"
                                  : "basic-select"
                              }
                              classNamePrefix="select"
                              options={p.selectOptions}
                              onChange={(e) => {
                                updateParameterStateSelect(
                                  chartIdx,
                                  e,
                                  p.parameterName
                                );
                              }}
                              required={p.isRequired}
                              value={
                                p.values !== null
                                  ? p.values
                                  : {
                                      label: p.selectOptions.find(
                                        (o) => o.value === p.defaultValue[0]
                                      ).label,
                                      value: p.defaultValue[0],
                                    }
                              }
                            />
                          )}
                        </DropdownItem>
                      ))}
                    <DropdownItem toggle={false} divider />
                    <Button
                      outline
                      onClick={() => toggleSaveChartDropdownMenu(x)}
                      disabled={
                        tmpMenuParameters.find(
                          (p) => p.values === null || p.values.length === 0
                        ) !== undefined
                      }
                      style={{ float: "right", marginRight: "24px" }}
                    >
                      {__("Zastosuj")}
                    </Button>
                    <Button
                      outline
                      onClick={() => toggleCancelChartDropdownMenu(x)}
                      style={{ float: "right", marginRight: "24px" }}
                    >
                      {__("Anuluj")}
                    </Button>
                  </DropdownMenu>
                </Dropdown>
                <div
                  style={{
                    display: x.isLoading ? "block" : "none",
                    position: "absolute",
                    background: "rgb(221 221 221 / 67%)",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      "-webkit-transform": "translate(-50%, -50%)",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {Spinner}
                  </div>
                </div>
                {}
              </div>
            ))}
          </ResponsiveGridLayout>
          {layoutState &&
            layoutState.layout &&
            layoutState.layout.length > 0 &&
            [
              ...Array(
                Math.max(
                  0,
                  ...layoutState.layout.map((obj) =>
                    Math.ceil((obj.y + obj.h) / 2)
                  )
                )
              ),
            ]
              .slice(0, -1)
              .map((e, i) => (
                <div
                  style={{
                    position: "absolute",
                    top: 26 + 410 * 2 * (i + 1),
                    left: -22,
                    background: "#fff",
                    border: "1px solid #d1d1d1",
                    borderRadius: 10,
                    width: 28,
                    padding: "2px 7px",
                    textAlign: "center",
                    boxShadow: "0 2px 2px #b5b5b5, 0 2px 5px #d1d1d1",
                    zIndex: 5,
                  }}
                >
                  <div>{i + 1}</div>
                  <div style={{ borderBottom: "2px dotted #d1d1d1" }} />
                  <div>{i + 2}</div>
                </div>
              ))}
          {layoutState &&
            layoutState.layout &&
            layoutState.layout.length > 0 &&
            [
              ...Array(
                Math.max(
                  0,
                  ...layoutState.layout.map((obj) =>
                    Math.ceil((obj.y + obj.h) / 2)
                  )
                )
              ),
            ]
              .slice(0, -1)
              .map((e, i) => (
                <div
                  style={{
                    position: "absolute",
                    top: 50 + 410 * 2 * (i + 1),
                    left: 0,
                    right: 0,
                    borderBottom: "2px dashed #d1d1d1",
                  }}
                />
              ))}
        </div>

        <Modal
          isOpen={modal}
          toggle={modalToggle}
          size="lg"
          style={{ marginRight: "0" }}
          scrollable
        >
          <ModalHeader toggle={modalToggle}>
            {__("Ustawienia raportu")}
          </ModalHeader>
          <ModalBody>
            <div>
              <span style={{ fontWeight: 700 }}>{__("Ustawienia ogólne")}</span>
            </div>
            <div style={{ padding: "5px 10px 0 10px" }}>
              <div>{__("Paleta barw")}</div>
              <div>
                {chartsConfiguration?.colorPalettes?.map((x) => (
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      cursor: "pointer",
                      height: "60px",
                      width: "30%",
                      border:
                        // eslint-disable-next-line
                        chartsConfiguration.selectedPaletteId == x.id
                          ? "2px solid #239d4e"
                          : "1px solid #ddd",
                      borderRadius: "3px",
                      background: "#fff",
                      padding: "5px",
                      marginRight: "5px",
                      marginBottom: "8px",
                      verticalAlign: "top",
                    }}
                    onClick={() => selectColorPalette(x)}
                    role="presentation"
                  >
                    {x.palette?.map((p) => (
                      <div
                        key={p}
                        style={{
                          display: "inline-block",
                          height: "49%",
                          width: "19%",
                          boxSizing: "content-box",
                          borderRadius: "3px",
                          background: p,
                          marginRight: "2px",
                          verticalAlign: "top",
                        }}
                      />
                    ))}

                    {
                      // eslint-disable-next-line
                      chartsConfiguration.selectedPaletteId == x.id && (
                        <div
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            top: "15px",
                            right: "70px",
                            background: "#239d4e",
                            color: "#fff",
                            borderRadius: "20px",
                            padding: "3px 10px",
                            margin: "5px",
                            zIndex: 100,
                          }}
                        >
                          {__("Wybrany")}
                        </div>
                      )
                    }
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div>
              <span style={{ fontWeight: 700 }}>{__("Ustawienia PDF")}</span>
            </div>
            <div style={{ padding: "5px 10px 0 10px" }}>
              <div>
                <div>{__("Tło raportu")}</div>
                <div>
                  {chartsConfiguration?.backgroundImages?.map((b) => (
                    <div
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        height: "120px",
                        width: "200px",
                        border: "1px solid #ddd",
                        borderRadius: "6px",
                        ...getBackgroundImage(b.value),
                        backgroundSize: "100% 100%",
                        marginRight: "5px",
                        marginBottom: "5px",
                        verticalAlign: "top",
                      }}
                      onClick={() => selectBackground(b.key)}
                      role="presentation"
                    >
                      {chartsConfiguration.selectedBackgroundImageId &&
                        // eslint-disable-next-line
                        chartsConfiguration.selectedBackgroundImageId ==
                          b.key && (
                          <div
                            style={{
                              display: "inline-block",
                              background: "#239d4e",
                              color: "#fff",
                              borderRadius: "20px",
                              padding: "3px 10px",
                              margin: "5px",
                            }}
                          >
                            {__("Wybrany")}
                          </div>
                        )}
                    </div>
                  ))}
                  {}
                </div>
              </div>
              <div>
                <div style={{ marginTop: "15px" }}>{__("Marginesy")}</div>
                <div
                  style={{
                    position: "relative",
                    background: "#eee",
                    height: "180px",
                    width: "300px",
                    border: "1px solid #ddd",
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      background: "#fff",
                      top: "45px",
                      bottom: "45px",
                      left: "100px",
                      right: "100px",
                      border: "1px solid #ddd",
                      borderRadius: "2px",
                    }}
                  />
                  <InputGroup
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "35%",
                      width: "auto",
                    }}
                  >
                    <Input
                      placeholder="0"
                      style={{ width: "45px" }}
                      maxLength="2"
                      value={chartsConfiguration?.margins?.top || ""}
                    />
                    <InputGroupText>px</InputGroupText>
                  </InputGroup>
                  <InputGroup
                    style={{
                      position: "absolute",
                      top: "40%",
                      right: "5px",
                      width: "auto",
                    }}
                  >
                    <Input
                      placeholder="0"
                      style={{ width: "45px" }}
                      maxLength="2"
                      value={chartsConfiguration?.margins?.right || ""}
                    />
                    <InputGroupText>px</InputGroupText>
                  </InputGroup>
                  <InputGroup
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      left: "35%",
                      width: "auto",
                    }}
                  >
                    <Input
                      placeholder="0"
                      style={{ width: "45px" }}
                      maxLength="2"
                      value={chartsConfiguration?.margins?.bottom || ""}
                    />
                    <InputGroupText>px</InputGroupText>
                  </InputGroup>
                  <InputGroup
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "5px",
                      width: "auto",
                    }}
                  >
                    <Input
                      placeholder="0"
                      style={{ width: "45px" }}
                      maxLength="2"
                      value={chartsConfiguration?.margins?.left || ""}
                    />
                    <InputGroupText>px</InputGroupText>
                  </InputGroup>
                </div>
              </div>
              <div>
                <div style={{ marginTop: "15px" }}>{__("Nagłówek")}</div>
                <Input
                  type="textarea"
                  style={{ width: "100%" }}
                  onChange={handleHtmlHeaderChange}
                  value={
                    chartsConfiguration ? chartsConfiguration.htmlHeader : ""
                  }
                />
                {}
              </div>
              <div>
                <div style={{ marginTop: "15px" }}>{__("Stopka")}</div>
                <Input
                  type="textarea"
                  style={{ width: "100%" }}
                  onChange={handleHtmlFooterChange}
                  value={
                    chartsConfiguration ? chartsConfiguration.htmlFooter : ""
                  }
                />
                {}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {}
            <Button color="primary" onClick={modalToggle}>
              {__("Zamknij")}
            </Button>
          </ModalFooter>
        </Modal>

        {coverVisible === true && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          />
        )}
      </div>
    </>
  );
};

VisualReport.propTypes = {
  companyId: PropTypes.string.isRequired,
};
export default VisualReport;

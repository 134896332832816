import React from "react";
import __ from "src/utils/Translations";
import { match as matchPropTypes } from "react-router-prop-types";
import ContentLoading from "src/Components/Loading/contentLoading";
import { useCompanyId } from "src/Pages/Company/CompanyContext";
import useQueryTotalRewardConfig from "src/Pages/Company/TotalReward/hooks/useQueryTotalRewardConfig";
import TotalRewardPerksForm from "src/Pages/Company/TotalReward/Perks/Edit/form";

export default function TotalRewardPerkEdit({ match }) {
  const { perkId } = match.params;
  const companyId = useCompanyId();
  const pathPrefix = `/ahr`;

  const { isLoading, getConfigValue } = useQueryTotalRewardConfig(companyId);

  return (
    <ContentLoading show={isLoading}>
      <TotalRewardPerksForm
        companyId={companyId}
        perkId={perkId}
        pathPrefix={pathPrefix}
        yourBenefitsEnabled={getConfigValue("your_benefits_module")}
        availableBenefitsEnabled={getConfigValue("available_benefits_module")}
      />
    </ContentLoading>
  );
}

TotalRewardPerkEdit.propTypes = {
  match: matchPropTypes.isRequired,
};

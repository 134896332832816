import React from "react";
import { match as matchPropTypes } from "react-router-prop-types";
import { useCompanyId, useCompanyName } from "src/Pages/Company/CompanyContext";
import { getAhrBaseBreadcrumbs } from "src/Pages/AhrCore/routerHelper";
import __ from "src/utils/Translations";
import ReportDetail from "src/Pages/Report/NewReport/ReportsList/Report";

export default function Details({ match }) {
  const companyName = useCompanyName();
  const companyId = useCompanyId();

  return (
    <ReportDetail
      selectedCompanyId={companyId}
      companyName={companyName}
      reportId={match.params.id}
      breadcrumbs={[
        ...getAhrBaseBreadcrumbs(companyName),
        { title: __("Raporty"), link: "/ahr/report" },
        { title: __("Lista raportów"), link: "/ahr/report/predefined-reports" },
      ]}
    />
  );
}

Details.propTypes = {
  match: matchPropTypes.isRequired,
};

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { REPORT_SERVICE, restApiRequest, getServiceHost } from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { archiveDetailsMock } from "src/Pages/Report/GeneratedReports/mockData";
import PageTitle from "src/Layout/AppMain/PageTitle";
import { dynamicNotification } from "src/utils/Notifications";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import __ from "src/utils/Translations";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import Data from "./data";
import Panel from "./panel";

const ArchiveDetails = ({
  archiveId,
  archiveListUrl,
  breadcrumbs,
  companyId,
}) => {
  const [archiveDetails, setArchiveDetails] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    fetchData(
      setArchiveDetails,
      `/archive/report/details?archivedReportId=${archiveId}&companyId=${companyId}`,
      REPORT_SERVICE,
      "GET",
      {},
      archiveDetailsMock,
      "Błąd podczas szczegółów z archiwum"
    );
  }, [archiveId, companyId]);

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const instantDownload = urlParams.get("instantDownload");

  useEffect(() => {
    if (instantDownload && archiveDetails) {
      if (instantDownload === "true" && archiveDetails.archivedReportId) {
        downloadReport();
      }
    }
    // eslint-disable-next-line
  }, [archiveDetails, instantDownload]);

  const history = useHistory();

  const deleteArchive = () => {
    fetchData(
      () => {
        history.push(archiveListUrl);
      },
      `/archive/report/file?archivedReportId=${archiveId}`,
      REPORT_SERVICE,
      "Delete",
      {},
      {},
      __("Błąd podczas usuwania w archiwum.")
    );
  };

  const downloadReport = async () => {
    if (!isMockView()) {
      setIsDownloading(true);

      const session = getSession();
      const server = getServiceHost(REPORT_SERVICE);
      const url = `${server}/archive/report/file?archivedReportId=${archiveId}"`;

      fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${await session.getValidAccessToken()}`,
        },

        // 1. Convert the data into 'blob'
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (blob.size !== 0) {
            // 2. Create blob link to download
            const urlfile = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = urlfile;
            link.setAttribute(
              "download",
              `${__(archiveDetails.reportName)}_${
                archiveDetails.modificationDate
              }.${archiveDetails.fileExtension}`
            );
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            setIsDownloading(false);
          } else {
            setIsDownloading(false);
          }
        }); // .then(() => callback())
    } else {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <PageTitle
        heading={__(archiveDetails.reportName)}
        breadcrumbs={breadcrumbs}
      />
      <div className="report-detail-margin-bottom">
        <Panel
          reportName={archiveDetails.reportName}
          deleteFunc={deleteArchive}
          downloadFunc={downloadReport}
          isDownloading={isDownloading}
          canDelete={archiveDetails.canDelete}
        />
      </div>
      <div className="report-detail-margin-bottom">
        <Data details={archiveDetails} />
      </div>
    </>
  );
};

export default ArchiveDetails;
ArchiveDetails.propTypes = {
  archiveId: PropTypes.string.isRequired,
  archiveListUrl: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
};

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params },
  mockData,
  error
) => {
  try {
    const result = await restApiRequest(
      service,
      endpoint,
      method,
      { headers, params },
      mockData
    );
    updateData(result);
  } catch (e) {
    console.error(e);
    newRelicErrorReport(e, "Pages/Report/GeneratedReports/List/index.js - 174");
    dynamicNotification(error || e.message || "błąd", "error");
  }
};

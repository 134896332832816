import {
  catalogCategoryPermissionRead,
  catalogProductPermissionRead,
  companyCompanyPermissionRead,
  configurationConfigurationPermissionRead,
  dictionaryDictionaryPermissionRead,
  employeeEmployeePermissionRead,
  employeeEmployeePermissionWrite,
  operatorOperatorPermissionRead,
  ssoIpAddressRestrictionPermissionRead,
  tourismTourismAttributePermissionRead,
  tourismTourismObjectPermissionRead,
  // translationTranslateCmsPermissionWrite,
  translationTranslatePermissionWrite,
  translationTranslationScopePermissionRead,
  subscriptionActiveFormPermissionRead,
  agreementAgreementPermissionRead,
  notificationAppMessagePermissionRead,
  subscriptionBenefitCategoryPermissionRead,
  magentoECommercePermissionAccess,
  employeeMultisportCardAdministrator,
  notificationSmsPermissionRead,
  companyReportAccessPasswordRead,
  subscriptionEmployeeSubscriptionItemAccept,
  catalogProductCodeTemplatePermissionRead,
  companyMyBenefitActiveRead,
  jobManagerProcessRead,
  employeeGroupProductConfigPermissionRead,
  reportsAhrGraphs,
  proposalsModulePermissionRead,
  totalRewardSettings,
  totalRewardPerkUsersRead,
  totalRewardPerkUsersImportExport,
  totalRewardPerksRead,
  totalRewardPerkCategoriesRead,
} from "src/utils/RoleBasedSecurity/permissions";
import diagnosticServices from "src/Pages/Diagnostic/services";

export const MainNav = [
  {
    icon: "pe-7s-diamond",
    label: "Katalog produktów",
    to: "/product-catalog",
    id: "productCatalog",
    content: [
      {
        icon: "pe-7s-diamond",
        label: "Lista produktów",
        to: "/product-catalog/products",
        id: "productCatalogList",
        aclKey: catalogProductPermissionRead,
      },
      {
        label: "Kategorie biznesowe",
        to: "/product-catalog/business-category",
        id: "businessCategory",
        aclKey: catalogCategoryPermissionRead,
        icon: "pe-7s-network",
      },
      {
        label: "Kategorie abonamentowe",
        to: "/product-catalog/subscription-category",
        id: "subscriptionCategory",
        aclKey: subscriptionBenefitCategoryPermissionRead,
        icon: "pe-7s-network",
      },
      {
        label: "Szablony kuponów",
        to: "/product-catalog/coupon-templates",
        id: "couponTemplates",
        aclKey: catalogProductCodeTemplatePermissionRead,
        icon: "pe-7s-network",
      },
      {
        label: "MyBenefit Active - produkty",
        to: "/product-catalog/discount-codes",
        id: "discountCodes",
        aclKey: catalogProductCodeTemplatePermissionRead,
        icon: "pe-7s-network",
      },
      {
        icon: "pe-7s-folder",
        label: "Karty przedpacone - produkty",
        to: "/product-catalog/prepaid-cards",
        id: "prepaidCardsList",
        aclKey: catalogProductPermissionRead,
      },
    ],
  },
  {
    icon: "pe-7s-shuffle",
    label: "Lista dostawców",
    to: "/suppliers",
    id: "suppliers",
    aclKey: tourismTourismObjectPermissionRead,
    isAnchor: true,
  },
  {
    label: "Turystyka",
    to: "/tourism",
    id: "tourism",
    icon: "pe-7s-map",
    content: [
      {
        label: "Lista obiektów",
        to: "/tourism/objects",
        id: "tourismListOfObjects",
        aclKey: tourismTourismObjectPermissionRead,
        tileIcon: "pe-7s-home",
      },
      {
        label: "Lista dostawców",
        to: "/tourism/suppliers",
        id: "tourismListOfSuppliers",
        aclKey: tourismTourismObjectPermissionRead,
        tileIcon: "pe-7s-box2",
      },
      {
        label: "Zarządzanie atrybutami filtrowania",
        to: "/tourism/managing-filtering-attributes",
        id: "tourismManagingFilteringAttributes",
        aclKey: tourismTourismAttributePermissionRead,
        tileIcon: "pe-7s-edit",
      },
      {
        label: "Diagnostyka",
        to: "/tourism/diagnostics",
        id: "tourismDiagnostics",
        aclKey: tourismTourismAttributePermissionRead,
        tileIcon: "pe-7s-edit",
      },
    ],
  },
  {
    icon: "pe-7s-id",
    label: "Firma",
    id: "company",
    to: "/company",
    content: [
      {
        label: "Lista firm",
        to: "/company/list",
        id: "companyList",
        aclKey: companyCompanyPermissionRead,
        tileIcon: "pe-7s-id",
      },
      {
        label: "Lista pracowników",
        to: "/company/employee-list",
        id: "employeeList",
        aclKey: employeeEmployeePermissionRead,
        tileIcon: "pe-7s-users",
      },
      {
        icon: "pe-7s-download",
        label: "SFTP - Import pracowników",
        to: "/import-sftp",
        id: "importSftp",
        aclKey: employeeEmployeePermissionWrite,
      },
    ],
  },
  {
    icon: "pe-7s-tools",
    label: "Administracja",
    id: "administration",
    to: "/administration",
    content: [
      {
        label: "Parametry konfiguracyjne",
        to: "/administration/configuration",
        id: "configuration",
        aclKey: configurationConfigurationPermissionRead,
        tileIcon: "pe-7s-config",
      },
      {
        label: "Słowniki",
        to: "/administration/dictionary",
        id: "dictionary",
        aclKey: dictionaryDictionaryPermissionRead,
        tileIcon: "pe-7s-config",
      },
      {
        label: "Konfiguracja adresów IP",
        to: "/administration/ip-security",
        id: "ipSecurity",
        aclKey: ssoIpAddressRestrictionPermissionRead,
        tileIcon: "pe-7s-unlock",
        notDisplay: true,
      },
      {
        label: "Szablony podpięć EGP",
        to: "/administration/egp-templates",
        id: "egpTemplate",
        aclKey: employeeGroupProductConfigPermissionRead,
        tileIcon: "pe-7s-config",
      },
    ],
  },
  {
    icon: "pe-7s-user",
    label: "Operatorzy MB",
    to: "/user",
    id: "operators",
    content: [
      {
        tileIcon: "pe-7s-user",
        label: "Lista operatorów",
        to: "/user/operator",
        id: "operatorList",
        aclKey: operatorOperatorPermissionRead,
      },
      {
        tileIcon: "pe-7s-users",
        label: "Lista ról",
        to: "/user/role",
        id: "operatorRoleList",
        aclKey: operatorOperatorPermissionRead,
      },
    ],
  },
  // Module probably to delete
  // {
  //   icon: 'pe-7s-box2',
  //   label: 'CMS',
  //   to: '/cms/management',
  //   isAnchor: true,
  //   id: 'cms',
  //   aclKey: cmsDocumentPermissionRead,
  // },
  {
    icon: "pe-7s-note2",
    label: "Formularze aktywne",
    to: "/active-forms",
    id: "activeForms",
    aclKey: subscriptionActiveFormPermissionRead,
  },
  {
    icon: "pe-7s-graph2",
    label: "Raporty",
    id: "report",
    to: "/report",
    content: [
      {
        label: "Raporty",
        to: "/report/predefined-reports",
        id: "new_report",
        // aclKey: 'new_report',
        tileIcon: "pe-7s-graph1",
      },
      {
        label: "Raporty graficzne",
        to: "/report/graph-reports",
        id: "graph_report",
        // aclKey: 'new_report',
        tileIcon: "pe-7s-graph",
      },
      {
        label: "Archiwum",
        to: "/report/archive",
        id: "archive",
        // aclKey: 'archive',
        tileIcon: "pe-7s-chat",
      },
      {
        label: "Aktywne wysyłki",
        to: "/report/subscriptions",
        id: "subscriptions",
        // aclKey: 'subscriptions',
        tileIcon: "pe-7s-edit",
      },
    ],
  },
  {
    icon: "pe-7s-check",
    label: "Zgody",
    to: "/agreements",
    id: "agreements",
    aclKey: agreementAgreementPermissionRead,
    content: [
      {
        label: "Zgody",
        to: "/agreements/predefined-agreements",
        id: "predefinedAgreements",
        aclKey: agreementAgreementPermissionRead,
        tileIcon: "pe-7s-check",
      },
      {
        label: "Ewidencja wysłanych regulaminów",
        to: "/agreements/sent-regulations",
        id: "sentRegulations",
        aclKey: agreementAgreementPermissionRead,
        tileIcon: "pe-7s-check",
      },
    ],
  },
  {
    icon: "pe-7s-comment",
    label: "Powiadomienia",
    id: "notification",
    to: "/notification",
    content: [
      {
        label: "Wiadomości wewnątrzsystemowe",
        to: "/notification/app-messages",
        id: "appMessages",
        aclKey: notificationAppMessagePermissionRead,
        tileIcon: "pe-7s-chat",
      },
      {
        label: "Wiadomości SMS",
        to: "/notification/sms",
        id: "sms",
        aclKey: notificationSmsPermissionRead,
        tileIcon: "pe-7s-phone",
      },
    ],
  },
  {
    icon: "pe-7s-notebook",
    label: "Tłumaczenia",
    id: "translate",
    to: "/translate",
    content: [
      {
        label: "Tłumaczenia interfejsu",
        to: "/translate/interface",
        id: "translateInterface",
        aclKey: translationTranslatePermissionWrite,
        tileIcon: "pe-7s-monitor",
      },
      {
        label: "Tłumaczenia wartości prostych",
        to: "/translate/simple",
        id: "translateSimple",
        aclKey: translationTranslatePermissionWrite,
        tileIcon: "pe-7s-display1",
      },
      // {
      //   label: 'Tłumaczenia opisów',
      //   to: '/translate/description',
      //   id: 'translateDescription',
      //   aclKey: translationTranslateCmsPermissionWrite,
      //   tileIcon: 'pe-7s-display2',
      // },
      {
        label: "Zakresy tłumaczeń",
        to: "/translate/scopes",
        id: "translateScopes",
        aclKey: translationTranslationScopePermissionRead,
        tileIcon: "pe-7s-albums",
      },
    ],
  },
  {
    icon: "pe-7s-download",
    label: "Importy",
    id: "imports",
    to: "/imports",
    content: [
      //     {
      //       label: 'Import dokumentów CMS',
      //       to: '/imports/cms',
      //       id: 'importCms',
      //       aclKey: cmsDocumentPermissionWrite,
      //       tileIcon: 'pe-7s-config',
      //     },
    ],
  },
  {
    icon: "pe-7s-graph2",
    label: "Diagnostyka",
    id: "diagnostic",
    to: "/diagnostic",
    content: diagnosticServices,
  },
  {
    icon: "pe-7s-display2",
    label: "Standardy",
    id: "standards",
    to: "/standards",
    dev: true,
    content: [
      {
        label: "Grid filtrowalny js",
        to: "/standards/grid/filtrable-js",
        id: "gridFiltrerableJs",
        tileIcon: "pe-7s-config",
      },
      {
        label: "Grid filtrowalny backend",
        to: "/standards/grid/filtrable-backend",
        id: "gridFiltrerableBackend",
        tileIcon: "pe-7s-config",
      },
      {
        label: "Formularze",
        to: "/standards/forms",
        id: "forms",
        tileIcon: "pe-7s-config",
      },
      {
        label: "Zakładki (proste)",
        to: "/standards/tabs/simple",
        id: "tabsSimple",
        tileIcon: "pe-7s-config",
      },
      {
        label: "Zakładki (z zapamiętywaniem)",
        to: "/standards/tabs/with-memo",
        id: "tabsWithMemo",
        tileIcon: "pe-7s-config",
      },
      {
        label: "Zakładki boczne",
        to: "/standards/tabs/sidebar",
        id: "tabsSidebar",
        tileIcon: "pe-7s-config",
      },
    ],
  },
  {
    icon: "pe-7s-shuffle",
    label: "Procesy",
    to: "/processes",
    id: "processes",
    aclKey: jobManagerProcessRead,
    isAnchor: true,
  },
  {
    icon: "pe-7s-shopbag",
    label: "Panel Magento",
    href: "/magento/admin",
    id: "magento",
    aclKey: magentoECommercePermissionAccess,
  },
];
export const AhrMainNav = [
  {
    icon: "pe-7s-user",
    label: "Pracownicy",
    to: "/ahr/employees",
    isAnchor: true,
    aclKey: employeeEmployeePermissionRead,
    id: "companyEmployee",
    tail: true,
  },
  {
    label: "Benefity oczekujące",
    icon: "pe-7s-cup",
    to: "/ahr/pending-benefits",
    aclKey: subscriptionEmployeeSubscriptionItemAccept,
    id: "pendingBenefits",
    tail: true,
  },
  {
    label: "Benefity oczekujące - MultiSport",
    icon: "pe-7s-cup",
    to: "/ahr/pending-benefits-multisport",
    aclKey: employeeMultisportCardAdministrator,
    id: "pendingBenefitsMultiSport",
    tail: true,
  },
  {
    icon: "pe-7s-graph2",
    label: "Raporty",
    to: "/ahr/report",
    id: "report",
    content: [
      {
        label: "Raporty",
        to: "/ahr/report/predefined-reports",
        id: "new_report",
        // aclKey: 'new_report',
        tileIcon: "pe-7s-graph1",
      },
      {
        label: "Raporty graficzne",
        to: "/ahr/report/graph-reports",
        id: "graph_report",
        aclKey: reportsAhrGraphs,
        tileIcon: "pe-7s-graph",
      },
      {
        label: "Archiwum",
        to: "/ahr/report/archive",
        id: "archive",
        // aclKey: 'archive',
        tileIcon: "pe-7s-chat",
      },
      {
        label: "Aktywne wysyłki",
        to: "/ahr/report/subscriptions",
        id: "subscriptions",
        // aclKey: 'subscriptions',
        tileIcon: "pe-7s-edit",
      },
    ],
    tail: true,
  },
  {
    icon: "pe-7s-comment",
    label: "Powiadomienia",
    id: "notification",
    to: "/ahr/notification",
    content: [
      {
        label: "Wiadomości wewnątrzsystemowe",
        to: "/ahr/notification/app-messages",
        id: "appMessages",
        aclKey: notificationAppMessagePermissionRead,
        tileIcon: "pe-7s-chat",
      },
    ],
    tail: true,
  },
  {
    icon: "pe-7s-unlock",
    label: "Dostępy",
    id: "access",
    aclKey: companyReportAccessPasswordRead,
    to: "/ahr/access",
    tail: true,
  },
  {
    icon: "pe-7s-note2",
    label: "Wnioski i Dofinansowania",
    id: "proposals",
    aclKey: proposalsModulePermissionRead,
    functionality: "ENABLE_PROPOSALS",
    to: "/ahr/proposals",
    tail: true,
  },
  {
    icon: "pe-7s-gym",
    label: "Karty Multisport",
    id: "multisport_cart",
    aclKey: employeeMultisportCardAdministrator,
    href: "/multisport",
    tail: false,
  },
  {
    icon: "pe-7s-smile",
    label: "MyBenefit Active",
    id: "myb_active",
    aclKey: companyMyBenefitActiveRead,
    functionality: "ENABLE_MYBENEFIT_ACTIVE",
    href: "{MyBenefitActiveUrl}",
    tail: false,
  },
  {
    icon: "pe-7s-smile",
    label: "Imprezy Firmowe",
    id: "companyEvents",
    to: `/ahr/company-events`,
    functionality: "ENABLE_COMPANY_EVENTS",
    tail: true,
  },
  {
    icon: "pe-7s-medal",
    label: "Total Reward Statement",
    id: "totalRewardStatement",
    to: `/ahr/total-reward`,
    functionality: "ENABLE_TOTAL_REWARD_STATEMENT",
    tail: true,
    content: [
      {
        label: "Przydzielone Benefity",
        id: "view_totalRewardPerkUsers",
        to: `/ahr/total-reward/perk-users`,
        tileIcon: "pe-7s-note2",
        aclKey: totalRewardPerkUsersRead,
      },
      {
        label: "Import/export benefitów",
        id: "view_totalRewardImportExportPerkUsers",
        to: `/ahr/total-reward/perkusers-import`,
        tileIcon: "pe-7s-repeat",
        aclKey: totalRewardPerkUsersImportExport,
      },
      {
        label: "Zarządzanie benefitami",
        id: "view_totalRewardPerks",
        to: `/ahr/total-reward/perks`,
        tileIcon: "pe-7s-tools",
        aclKey: totalRewardPerksRead,
      },
      {
        label: "Zarządzanie kategoriami",
        id: "view_totalRewardCategories",
        to: `/ahr/total-reward/categories`,
        tileIcon: "pe-7s-drawer",
        aclKey: totalRewardPerkCategoriesRead,
      },
      {
        icon: "pe-7s-settings",
        label: "Ustawienia modułu",
        id: "view_totalRewardSettings",
        to: `/ahr/total-reward/settings`,
        aclKey: totalRewardSettings,
        tileIcon: "pe-7s-settings",
      },
    ],
  },
  {
    icon: "pe-7s-shopbag",
    label: "Powrót do Systemu Kafeteryjnego",
    id: "back_to_pwa",
    href: "/magento/pwa",
    before: "reimpersonate",
    tail: true,
  },
];

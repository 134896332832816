import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import __ from "src/utils/Translations";
import { useLanguages } from "src/utils/Languages/LanguageContext";
import { correctLanguageParameters } from "src/Pages/Report/helpers/paramtersHelper";

const Data = ({ details }) => {
  const languages = useLanguages(true);

  const renderParameters = () => {
    if (details.parameters) {
      // eslint-disable-next-line no-param-reassign
      details.parameters = correctLanguageParameters(
        details.parameters,
        languages
      );
      return details.parameters.map((p) => (
        <FormGroup key={p.label}>
          <Label for={p.label}>{__(p.label)}</Label>
          {renderValues(p)}
        </FormGroup>
      ));
    }
    return "";
  };

  // eslint-disable-next-line max-len
  const renderValues = (parameter) =>
    parameter.values.map((p) => (
      <Input
        key={p.value}
        type="text"
        name={parameter.label}
        disabled
        value={__(p.value)}
      />
    ));

  return (
    <>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <CardTitle>
                {__("Podstawowe dane")}
                <hr />
              </CardTitle>
              <Form>
                <FormGroup>
                  <Label for="reportGroup">{__("Grupa")}</Label>
                  <Input
                    type="text"
                    name="reportGroups"
                    disabled
                    value={__(details.reportGroup)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="reportName">{__("Nazwa raportu")}</Label>
                  <Input
                    type="text"
                    name="reportName"
                    value={__(details.reportName)}
                    disabled
                  />
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="saveDate">{__("Data zapisania")}</Label>
                      <Input
                        type="text"
                        name="saveDate"
                        value={__(details.modificationDateStr)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="reportFormat">{__("Format")}</Label>
                      <Input
                        type="text"
                        name="reportFormat"
                        value={__(details.fileExtension)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="source">{__("Źródło")}</Label>
                  <Input
                    type="text"
                    name="source"
                    value={__(details.sourceName)}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="createdBy">{__("Utworzone przez")}</Label>
                  <Input
                    type="text"
                    name="createdBy"
                    value={__(details.creator)}
                    disabled
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <CardBody>
              <CardTitle>
                {__("Parametry raportu")}
                <hr />
              </CardTitle>
              <Form>{renderParameters()}</Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Data.propTypes = {
  details: PropTypes.objectOf.isRequired,
};
export default Data;

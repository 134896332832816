/* eslint-disable import/prefer-default-export */
import React from "react";
import CompanyContext from "src/Pages/Company/CompanyContext";
import __ from "src/utils/Translations";
import {
  banksBanksPermissionRead,
  companyCompanyPermissionRead,
  companyFundingPermissionRead,
  companyMultisportCardWaitingRoom,
  employeeAhrRolePermissionRead,
  employeeEmployeeGroupPermissionRead,
  employeeEmployeeGroupPermissionWrite,
  employeeEmployeePermissionRead,
  employeeEmployeePermissionWrite,
  employeeGroupProductConfigPermissionRead,
  employeeOrganizationUnitPermissionRead,
  employeeRentableGroupPermissionRead,
  companyTokenPermissionRead,
  subscriptionBenefitPermissionRead,
  subscriptionBenefitPermissionWrite,
  notificationAppMessagePermissionRead,
  configurationConfigurationPermissionRead,
  subscriptionEmployeeSubscriptionItemRead,
  companyAccessToSportsCards,
  employeeRentableGroupSelectionWindowPermissionRead,
  companyReportAccessPasswordRead,
  companyMyBenefitActiveRead,
  // companyPrepaidCardWrite,
  proposalsModulePermissionRead,
  totalRewardSettings,
  totalRewardPerkUsersRead,
  totalRewardPerkUsersImportExport,
  totalRewardPerksRead,
  totalRewardPerkCategoriesRead,
} from "src/utils/RoleBasedSecurity/permissions";

export const CompanySubMenu = {
  code: "company",
  getTitle: (location) => (
    <div className="text-wrap">
      {__("Zarządzanie firmą")}
      <CompanyContext.Consumer>
        {({ data }) => (data && data.fullName ? `: ${data.fullName}` : "")}
      </CompanyContext.Consumer>
    </div>
  ),
  getId: (location) => location.split("/")[3],
  parent_id: "company_list",
  getConfig: (location, companyId = null, excludeIds = []) => {
    if (!companyId) {
      // eslint-disable-next-line no-param-reassign
      companyId = CompanySubMenu.getId(location);
    }
    const prefix = `/company/edit/${companyId}`;
    return [
      {
        label: "Edycja firmy",
        to: `${prefix}/data`,
        id: "companyEdit",
        permission: companyCompanyPermissionRead,
        icon: "pe-7s-config",
      },
      {
        label: "Zarządzanie pracownikami",
        to: `${prefix}/employee-management`,
        id: "employeeManagement",
        icon: "pe-7s-user",
        content: [
          {
            label: "Pracownicy",
            to: `${prefix}/employee-management/employees`,
            id: "companyEmployee",
            aclKey: employeeEmployeePermissionRead,
            tileIcon: "pe-7s-user",
          },
          {
            label: "Administratorzy",
            to: `${prefix}/employee-management/ahr`,
            id: "companyAhrs",
            aclKey: employeeAhrRolePermissionRead,
            tileIcon: "pe-7s-tools",
          },
          {
            label: "Grupy pracownicze",
            to: `${prefix}/employee-management/employee-groups`,
            id: "employeeGroups",
            aclKey: employeeEmployeeGroupPermissionRead,
            tileIcon: "pe-7s-users",
          },
          {
            label: "Grupy dochodowości",
            to: `${prefix}/employee-management/rentable-groups`,
            id: "rentableGroups",
            aclKey: employeeRentableGroupPermissionRead,
            tileIcon: "pe-7s-users",
          },
          {
            label: "Zarządzanie oknami grup dochodowości",
            to: `${prefix}/employee-management/rentable-group-selection-windows`,
            id: "rentableGroupSelectionWindow",
            aclKey: employeeRentableGroupSelectionWindowPermissionRead,
            tileIcon: "pe-7s-photo-gallery",
          },
          {
            label: "Jednostki organizacyjne",
            to: `${prefix}/employee-management/organization-units`,
            id: "organization_units",
            aclKey: employeeOrganizationUnitPermissionRead,
            tileIcon: "pe-7s-add-user",
          },
          {
            label: "Działy w firmie",
            to: `${prefix}/employee-management/departments`,
            id: "company_departments",
            aclKey: employeeEmployeePermissionRead,
            tileIcon: "pe-7s-users",
          },
        ],
      },
      {
        label: "Abonamenty",
        to: `${prefix}/subscriptions`,
        id: "subscriptionsConfig",
        icon: "pe-7s-diamond",
        functionality: "ENABLE_PERIOD_SUBSCRIPTION_AND_IDEAL_CAFETERIA",
        content: [
          {
            label: "Świadczenia abonamentowe",
            to: `${prefix}/subscriptions/benefits`,
            id: "subscriptionsBenefits",
            aclKey: subscriptionBenefitPermissionRead,
            tileIcon: "pe-7s-diamond",
            isAnchor: true,
          },
          {
            label: "Konfiguracja abonamentów",
            to: `${prefix}/subscriptions/config`,
            id: "subscriptionsBenefitsConfig",
            aclKey: subscriptionBenefitPermissionRead,
            tileIcon: "pe-7s-diamond",
          },
        ],
      },
      {
        label: "Abonamenty pozostałe (UMS)",
        to: `${prefix}/ums-subscriptions`,
        id: "umsSubscriptionsConfig",
        icon: "pe-7s-diamond",
        functionality: "ENABLE_OTHER_PERIOD_SUBSCRIPTION_UMS",
        content: [
          {
            label: "Świadczenia abonamentowe",
            to: `${prefix}/ums-subscriptions/benefits`,
            id: "subscriptionsUmsBenefits",
            aclKey: subscriptionBenefitPermissionRead,
            tileIcon: "pe-7s-diamond",
            isAnchor: true,
          },
          {
            label: "Konfiguracja abonamentów",
            to: `${prefix}/ums-subscriptions/config`,
            id: "subscriptionsUmsBenefitsConfig",
            aclKey: subscriptionBenefitPermissionRead,
            tileIcon: "pe-7s-diamond",
          },
        ],
      },
      {
        label: "Zarządzanie produktami",
        to: `${prefix}/products`,
        id: "companyProducts",
        icon: "pe-7s-gift",
        content: [
          // Probably to delete with components
          // {
          //   label: 'Produkty zgrupowane',
          //   to: `${prefix}/products/clusters-benefits`,
          //   id: 'benefits_grouped',
          //   aclKey: 'benefits_grouped',
          //   tileIcon: 'pe-7s-plugin',
          // },
          {
            label: "Produkty w firmie",
            to: `${prefix}/products/egp`,
            id: "employeeGroupProducts",
            aclKey: employeeGroupProductConfigPermissionRead,
            tileIcon: "pe-7s-diamond",
          },
          {
            label: "Wyszukiwarka produktów",
            to: `${prefix}/products/search`,
            id: "employeeProductsSearch",
            aclKey: employeeGroupProductConfigPermissionRead,
            tileIcon: "pe-7s-diamond",
          },
        ],
      },
      {
        label: "Akceptacje",
        to: `${prefix}/pending`,
        id: "pending",
        icon: "pe-7s-next",
        functionalities: [
          "ENABLE_PERIOD_SUBSCRIPTION_AND_IDEAL_CAFETERIA",
          "ENABLE_OTHER_PERIOD_SUBSCRIPTION_UMS",
        ],
        content: [
          {
            label: "Benefity oczekujące",
            to: `${prefix}/pending/common-benefits`,
            id: "pendingBenefits",
            aclKey: subscriptionEmployeeSubscriptionItemRead,
            tileIcon: "pe-7s-next",
          },
          {
            label: "Benefity oczekujące - MultiSport",
            to: `${prefix}/pending/benefits-multisport`,
            id: "pendingBenefitsMultiSport",
            aclKey: companyMultisportCardWaitingRoom,
            tileIcon: "pe-7s-next",
          },
        ],
      },
      {
        icon: "pe-7s-next",
        label: "Benefity oczekujące - MultiSport",
        to: `${prefix}/pending/benefits-multisport`,
        id: "pendingBenefitsMultiSport",
        aclKey: companyMultisportCardWaitingRoom,
      },
      {
        label: "Banki punktów",
        to: `${prefix}/banks`,
        id: "companyBanks",
        icon: "pe-7s-piggy",
        content: [
          {
            label: "Konfiguracja banków punktów",
            to: `${prefix}/banks/config`,
            id: "companyBanksConfig",
            aclKey: banksBanksPermissionRead,
            tileIcon: "pe-7s-config",
          },
          {
            label: "Zarządzanie bankami punktów",
            to: `${prefix}/banks/management`,
            id: "companyBanksManagement",
            aclKey: banksBanksPermissionRead,
            tileIcon: "pe-7s-magic-wand",
          },
        ],
      },
      // Module probably to delete
      // {
      //   label: 'CMS',
      //   to: `${prefix}/cms/management`,
      //   id: 'cms',
      //   aclKey: cmsDocumentPermissionRead,
      //   icon: 'pe-7s-plugin',
      // },
      {
        label: "Zarządzanie tokenami",
        to: `${prefix}/token_management`,
        id: "tokenManagement",
        aclKey: companyTokenPermissionRead,
        icon: "pe-7s-lock",
      },
      {
        label: "Parametry konfiguracyjne",
        to: `${prefix}/configuration`,
        id: "configuration",
        aclKey: configurationConfigurationPermissionRead,
        icon: "pe-7s-config",
        isAnchor: true,
      },
      {
        icon: "pe-7s-comment",
        label: "Powiadomienia",
        id: "notification",
        to: `${prefix}/notification`,
        content: [
          {
            label: "Wiadomości wewnątrzsystemowe",
            to: `${prefix}/notification/app-messages`,
            id: "appMessages",
            aclKey: notificationAppMessagePermissionRead,
            tileIcon: "pe-7s-chat",
          },
        ],
      },
      {
        label: "Importy",
        to: `${prefix}/imports`,
        id: "imports",
        icon: "pe-7s-download",
        content: [
          {
            label: "Import pracowników",
            to: `${prefix}/imports/employee`,
            id: "importsEmployee",
            aclKey: employeeEmployeePermissionWrite,
            tileIcon: "pe-7s-add-user",
          },
          {
            label: "Import kart sportowych",
            to: `${prefix}/imports/sport-cards`,
            id: "importsSportCards",
            aclKey: subscriptionBenefitPermissionWrite,
            tileIcon: "pe-7s-ball",
          },
          {
            label: "Import rezygnacji z kart sportowych",
            to: `${prefix}/imports/resignation-sport-cards`,
            id: "importsSportCardsResignation",
            aclKey: subscriptionBenefitPermissionWrite,
            tileIcon: "pe-7s-config",
          },
          {
            label: "Import świadczeń abonamentowych",
            to: `${prefix}/imports/subscriptions`,
            id: "importsSubscriptions",
            aclKey: subscriptionBenefitPermissionWrite,
            tileIcon: "pe-7s-diamond",
          },
          {
            label: "Import anulacji świadczeń abonamentowych",
            to: `${prefix}/imports/resignation-subscriptions`,
            id: "importsSubscriptionsResignation",
            aclKey: subscriptionBenefitPermissionWrite,
            tileIcon: "pe-7s-config",
          },
          {
            label: "Import formularzy świadczeń abonamentowych",
            to: `${prefix}/imports/forms-subscriptions`,
            id: "importsSubscriptionsForms",
            aclKey: subscriptionBenefitPermissionWrite,
            tileIcon: "pe-7s-note2",
          },
          {
            label: "Import zmian numerów FK i maili",
            to: `${prefix}/imports/email-fk-changes`,
            id: "importsEmailFkChanges",
            aclKey: employeeEmployeePermissionWrite,
            tileIcon: "pe-7s-id",
          },
          {
            label: "Import dezaktywacji kont pracowników",
            to: `${prefix}/imports/deactivate-employee`,
            id: "importsDeactivateEmployee",
            aclKey: employeeEmployeePermissionWrite,
            tileIcon: "pe-7s-delete-user",
          },
          {
            label: "Import grup pracowniczych i grup dochodowości",
            to: `${prefix}/imports/groups`,
            id: "importsGroups",
            aclKey: employeeEmployeeGroupPermissionWrite,
            tileIcon: "pe-7s-users",
          },
          {
            label: "Import zmian cech pracowników",
            to: `${prefix}/imports/change-employee-attributes`,
            id: "importsEmployeeAttributesChanges",
            aclKey: employeeEmployeePermissionWrite,
            tileIcon: "pe-7s-magic-wand",
          },
          {
            label: "Import dofinansowań",
            to: `${prefix}/imports/fundings`,
            id: "importsFundings",
            aclKey: companyFundingPermissionRead,
            tileIcon: "pe-7s-cash",
          },
          {
            label: "Import zmiany daty startu kont",
            to: `${prefix}/imports/change-account-start-date`,
            id: "importsAccountStartDateChanges",
            aclKey: employeeEmployeePermissionWrite,
            tileIcon: "pe-7s-magic-wand",
          },
        ],
      },
      {
        icon: "pe-7s-unlock",
        label: "Dostępy",
        id: "access",
        aclKey: companyReportAccessPasswordRead,
        to: `${prefix}/access`,
      },
      {
        label: "Konfigurator Kart Sportowych",
        id: "configuration_sports_cards",
        href: `/ui/msi-contract-configurator/company/${companyId}/company-contract/list`,
        aclKey: companyAccessToSportsCards,
        icon: "pe-7s-ball",
      },
      {
        icon: "pe-7s-smile",
        label: "MyBenefit Active",
        id: "myb_active",
        aclKey: companyMyBenefitActiveRead,
        functionality: "ENABLE_MYBENEFIT_ACTIVE",
        href: "{MyBenefitActiveUrl}",
      },
      {
        icon: "pe-7s-cash",
        label: "Karty Przedpłacone",
        id: "prepaidCards",
        to: `${prefix}/prepaid-cards`,
        // aclKey: companyPrepaidCardWrite,
        functionality: "ENABLE_PREPAID_CARDS",
        content: [
          {
            icon: "pe-7s-cash",
            label: "Edycja kart przedpłaconych",
            id: "edit_KPP",
            aclKey: companyCompanyPermissionRead,
            to: `${prefix}/prepaid-cards/data`,
            tileIcon: "pe-7s-cash",
          },
          {
            icon: "pe-7s-unlock",
            label: "Export kart przedpłaconych",
            id: "export_KPP",
            aclKey: companyCompanyPermissionRead,
            to: `${prefix}/prepaid-cards/export`,
            tileIcon: "pe-7s-add-user",
          },
          {
            icon: "pe-7s-unlock",
            label: "Import kart przedpłaconych",
            id: "import_KPP",
            aclKey: companyCompanyPermissionRead,
            to: `${prefix}/prepaid-cards/import`,
            tileIcon: "pe-7s-add-user",
          },
        ],
      },
      {
        icon: "pe-7s-smile",
        label: "Imprezy Firmowe",
        id: "companyEvents",
        to: `${prefix}/company-events`,
        tileIcon: "pe-7s-smile",
        functionality: "ENABLE_COMPANY_EVENTS",
      },
      {
        icon: "pe-7s-drawer",
        label: "Wnioski i dofinansowania",
        id: "proposals",
        to: `${prefix}/proposals`,
        functionality: "ENABLE_PROPOSALS",
        content: [
          {
            icon: "pe-7s-unlock",
            label: "Zarządzanie",
            id: "proposals_management",
            aclKey: proposalsModulePermissionRead,
            to: `${prefix}/proposals/management`,
            tileIcon: "pe-7s-drawer",
          },
          {
            icon: "pe-7s-unlock",
            label: "Uprawnienia ról",
            id: "proposals_roles_privileges",
            aclKey: proposalsModulePermissionRead,
            to: `${prefix}/proposals/privileges/roles`,
            tileIcon: "pe-7s-add-user",
          },
          {
            icon: "pe-7s-unlock",
            label: "Uprawnienia użytkowników",
            id: "proposals_users_privileges",
            aclKey: proposalsModulePermissionRead,
            to: `${prefix}/proposals/privileges/users`,
            tileIcon: "pe-7s-add-user",
          },
        ],
      },
      {
        icon: "pe-7s-medal",
        label: "Total Reward Statement",
        id: "totalRewardStatement",
        to: `${prefix}/total-reward`,
        functionality: "ENABLE_TOTAL_REWARD_STATEMENT",
        content: [
          {
            icon: "pe-7s-note2",
            label: "Przydzielone Benefity",
            id: "view_totalRewardPerkUsers",
            to: `${prefix}/total-reward/perk-users`,
            aclKey: totalRewardPerkUsersRead,
            tileIcon: "pe-7s-note2",
          },
          {
            icon: "pe-7s-repeat",
            label: "Import/export benefitów",
            id: "view_totalRewardImportExportPerkUsers",
            to: `${prefix}/total-reward/perkusers-import`,
            aclKey: totalRewardPerkUsersImportExport,
            tileIcon: "pe-7s-repeat",
          },
          {
            icon: "pe-7s-tools",
            label: "Zarządzanie benefitami",
            id: "view_totalRewardPerks",
            to: `${prefix}/total-reward/perks`,
            aclKey: totalRewardPerksRead,
            tileIcon: "pe-7s-tools",
          },
          {
            icon: "pe-7s-drawer",
            label: "Zarządzanie kategoriami",
            id: "view_totalRewardCategories",
            to: `${prefix}/total-reward/categories`,
            aclKey: totalRewardPerkCategoriesRead,
            tileIcon: "pe-7s-drawer",
          },
          {
            icon: "pe-7s-settings",
            label: "Ustawienia modułu",
            id: "view_totalRewardSettings",
            to: `${prefix}/total-reward/settings`,
            aclKey: totalRewardSettings,
            tileIcon: "pe-7s-settings",
          },
        ],
      },
    ].filter((el) => !excludeIds.includes(el.id));
  },
};

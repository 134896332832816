/* eslint-disable no-underscore-dangle, react/destructuring-assignment */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "reactstrap";
import DataTable from "src/Components/DataTable";
import {
  DateFilter,
  dateFilterMethod,
  SelectFilter,
} from "src/Components/DataTable/filters";
import {
  getDateCell,
  mapValueFromOptions,
  priceColumn,
} from "src/Components/DataTable/commonCells";
import __ from "src/utils/Translations";
import {
  benefitIdColumn,
  POPUP_TYPE_CANCEL,
  POPUP_TYPE_CHANGE,
  POPUP_TYPE_RESIGN,
  // POPUP_TYPE_SUSPEND,
  POPUP_TYPE_CHANGE_DATA,
  POPUP_TYPE_SHOW_PERSONS,
  STATUS_PENDING,
  statusesOptions,
  hasFormsWithNames,
} from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import ActionColumn from "src/Components/DataTable/actionColumn";
import {
  subscriptionEmployeeSubscriptionItemBlockade,
  subscriptionEmployeeSubscriptionItemCancel,
  subscriptionEmployeeSubscriptionItemChangeDates,
  subscriptionEmployeeSubscriptionItemResign,
  subscriptionEmployeeSubscriptionItemUpdateForm,
  subscriptionEmployeeSubscriptionItemUpdatePersonalData,
} from "src/utils/RoleBasedSecurity/permissions";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import BenefitNameColumn from "src/Pages/Company/Pending/BenefitsPending/Listing/Table/benefitNameColumn";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";

const ActiveSubscriptions = ({ items, openPopup, companyId }) => {
  const rbsContext = useContext(RbsContext);
  const isAhr = rbsContext.userInfo.isAhr();
  const hasBlockadePermissions = useHasPermission(
    subscriptionEmployeeSubscriptionItemBlockade
  );
  const hasResignedPermissions = useHasPermission(
    subscriptionEmployeeSubscriptionItemResign
  );

  const ahrHasNoPermissions =
    !hasBlockadePermissions && !hasResignedPermissions;

  const hasAnyItemGraceDate = items.some((activeItem) => activeItem.graceDate);
  const hasAnyItemExpectedEndsAt = items.some(
    (activeItem) =>
      activeItem?.receiver?.expectedEndsAt || activeItem.expectedEndsAt
  );
  return (
    <Card>
      <CardHeader>
        {__("Zawieszanie i rezygnacja ze świadczeń abonamentowych")}
      </CardHeader>
      <CardBody>
        <DataTable
          id="activeSubscriptions"
          key={`activeSubscriptions_${items.length}`}
          data={items}
          showPagination
          defaultPageSize={20}
          filterable
          count={items.length}
          noCards
          columns={[
            {
              Header: "Nazwa",
              accessor: "benefitName",
              Cell: BenefitNameColumn,
            },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectFilter(statusesOptions),
              Cell: mapValueFromOptions(statusesOptions, "status"),
            },
            {
              Header: "Data startu",
              accessor: "startsAt",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              Cell: getDateCell("startsAt", false, true),
            },
            {
              Header: "Data karencji",
              accessor: "graceDate",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              doNotDisplay: !hasAnyItemGraceDate,
              Cell: getDateCell("graceDate", false, true),
            },
            {
              Header: () => (
                <>
                  <span className="mr-2">
                    {__("Do kiedy opłacone jest świadczenie")}
                  </span>
                  <DefaultTooltip
                    id="expected-ends-at-info"
                    content={__(
                      "Dla banku punktów bez doładowania cyklicznego data wskazuje do kiedy zablokowane są punkty na poczet opłacenia świadczenia. Dla banku punktów zasilanego cyklicznie wyświetlana data to zawsze koniec roku obrachunkowego."
                    )}
                  />
                </>
              ),
              accessor: "expectedEndsAt",
              Filter: DateFilter(),
              minWidth: 150,
              filterMethod: dateFilterMethod,
              doNotDisplay: !hasAnyItemExpectedEndsAt,
              Cell: getDateCell("expectedEndsAt", false, true),
            },
            // columns hidden temporarily by request - FEN-14020
            // {
            //   Header: "Zawieszenie od",
            //   accessor: "suspendedFrom",
            //   Filter: DateFilter(),
            //   minWidth: 150,
            //   filterMethod: dateFilterMethod,
            //   Cell: getDateCell("suspendedFrom", false, true),
            // },
            // {
            //   Header: "Zawieszenie do",
            //   accessor: "suspendedTo",
            //   Filter: DateFilter(),
            //   minWidth: 150,
            //   filterMethod: dateFilterMethod,
            //   Cell: getDateCell("suspendedTo", false, true),
            // },
            {
              Header: "ID benefitu w firmie",
              accessor: "benefit",
              doNotDisplay: isAhr,
              company: companyId,
              Cell: benefitIdColumn,
            },
            {
              Header: "Koszt pracownika",
              accessor: "employeePrice",
              Cell: priceColumn,
            },
            {
              Header: () => (
                <>
                  <span className="mr-2">{__("Koszt pracodawcy")}</span>
                  <DefaultTooltip
                    id="employer-price-info"
                    content={__(
                      "W zależności od grupy abonamentowej przez koszt pracodawcy rozumiemy limit pracodawcy lub punkty z banków punktów"
                    )}
                  />
                </>
              ),
              accessor: "employerPriceSummary",
              Cell: priceColumn,
            },
            {
              Header: "Data akceptacji w benefitach oczekujących",
              accessor: "acceptedAt",
              Cell: getDateCell("acceptedAt", false, true),
              filterable: false,
            },
            {
              Header: "Data wypełnienia danych osobowych",
              accessor: "dataProvidedAt",
              Cell: getDateCell("dataProvidedAt", false, true),
              filterable: false,
            },
            {
              Header: "Akcja",
              accessor: "action",
              filterable: false,
              sortable: false,
              minWidth: 150,
              doNotDisplay: ahrHasNoPermissions,
              Cell: (cell) => {
                const originalData = cell.original;
                const rowId = cell.row._original.id;
                const isPending = cell.row._original.status === STATUS_PENDING;
                const hasAnyFormsWithNames = hasFormsWithNames(originalData);
                const isThatBenefitWithGroupPurchaseMethod =
                  cell.row._original.packageType === "group";
                const hasAnyReceiversGroupPurchaseMethod =
                  originalData.allReceiversFromSubscriptionItem.length;
                return (
                  <div className="w-100">
                    <ActionColumn
                      data={cell.row._original}
                      buttons={[
                        {
                          id: "cancel",
                          permission:
                            subscriptionEmployeeSubscriptionItemCancel,
                          onClick: () =>
                            openPopup({ type: POPUP_TYPE_CANCEL, id: rowId }),
                          label: "Anuluj",
                          doNotDisplay: isAhr,
                        },
                        {
                          id: "change",
                          permission:
                            subscriptionEmployeeSubscriptionItemChangeDates,
                          onClick: () =>
                            openPopup({ type: POPUP_TYPE_CHANGE, id: rowId }),
                          label: "Zmień daty",
                          disabled:
                            isPending ||
                            !subscriptionEmployeeSubscriptionItemUpdateForm,
                          doNotDisplay:
                            isAhr || isThatBenefitWithGroupPurchaseMethod, // Display only for INDIVIDUAL purchase Method
                        },
                        {
                          id: "resign",
                          permission:
                            subscriptionEmployeeSubscriptionItemResign,
                          onClick: () =>
                            openPopup({
                              type: POPUP_TYPE_RESIGN,
                              id: rowId,
                              forceProcessItem:
                                isThatBenefitWithGroupPurchaseMethod,
                            }),
                          label: "Rezygnuj",
                          disabled: isPending,
                          doNotDisplay:
                            isPending ||
                            !hasResignedPermissions ||
                            (isAhr && isThatBenefitWithGroupPurchaseMethod),
                        },
                        // {
                        //   id: "suspend",
                        //   permission:
                        //     subscriptionEmployeeSubscriptionItemBlockade,
                        //   onClick: () => openPopup(POPUP_TYPE_SUSPEND, rowId),
                        //   label: "Zawieś",
                        //   disabled: isPending,
                        //   doNotDisplay: isPending || !hasBlockadePermissions,
                        // },
                        {
                          id: "changeData",
                          permission:
                            subscriptionEmployeeSubscriptionItemUpdatePersonalData,
                          onClick: () =>
                            openPopup({
                              type: POPUP_TYPE_CHANGE_DATA,
                              id: rowId,
                            }),
                          label: "Zmień dane",
                          disabled: !hasAnyFormsWithNames,
                          doNotDisplay: isThatBenefitWithGroupPurchaseMethod, // Display only for INDIVIDUAL purchase Method
                          hideIfNoPermission: true,
                        },
                        {
                          id: "showPersons",
                          onClick: () =>
                            openPopup({
                              type: POPUP_TYPE_SHOW_PERSONS,
                              id: rowId,
                            }),
                          label: "Pokaż osoby",
                          doNotDisplay: !isThatBenefitWithGroupPurchaseMethod, // Display only for GROUP purchase Method
                          disabled: !hasAnyReceiversGroupPurchaseMethod,
                        },
                      ]}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      </CardBody>
    </Card>
  );
};
ActiveSubscriptions.propTypes = {
  companyId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openPopup: PropTypes.func.isRequired,
};

export default ActiveSubscriptions;
